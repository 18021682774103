import clsx from 'clsx';
import { ComponentPropsWithoutRef, FC } from 'react';

import { BackTop } from '@/components';
import S from './scene.module.less';
import { useScrollContainerRef } from './scroll-container.hook';

export const DetailsScene: FC<ComponentPropsWithoutRef<'div'>> = (props) => {
  const scrollerRef = useScrollContainerRef();

  return (
    <div
      ref={scrollerRef}
      {...props}
      className={clsx(S.details, props.className)}
    >
      {props.children}
      <BackTop />
    </div>
  );
};
