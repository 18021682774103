import axios from 'axios';
import { useQuery } from '@umijs/max';

/**
 * 任务通知
 */
export interface TaskNotificationDto {
  id: number;
  uid: string;
  label: string;
  type: string;
  message: string;
}
const TASK_NOTIFICATION = ['TASK_NOTIFICATION'];

async function getTaskNotification(): Promise<TaskNotificationDto[]> {
  const { data } = await axios.get<TaskNotificationDto[]>(
    'api/job-statuses/notify',
  );
  return data;
}

export const useTaskNotification = () => {
  return useQuery({
    queryKey: TASK_NOTIFICATION,
    queryFn: async (): Promise<TaskNotificationDto[]> => {
      const data = await getTaskNotification();
      return data;
    },
    refetchInterval: 60 * 1000,
  });
};
