import clsx from 'clsx';
import { ComponentPropsWithoutRef, FC } from 'react';

import { BackTop } from '@/components';
import { useScrollContainerRef } from './scroll-container.hook';

import S from './table-list.module.less';

export const TableListScene: FC<ComponentPropsWithoutRef<'div'>> = (props) => {
  const { children, ...restProps } = props;

  const scrollerRef = useScrollContainerRef();

  return (
    <div {...restProps} className={clsx(S.content, props.className)}>
      <div ref={scrollerRef} className={S['content-container']}>
        {children}
        <BackTop></BackTop>
      </div>
    </div>
  );
};
