import { TableProps as AntdTableProps } from 'antd';
import type { ExpandableConfig as AntdExpandableConfig } from 'antd/es/table/interface';
import { Key, useMemo } from 'react';

import clsx from 'clsx';
import { ExpandIcon } from './expand-icon';
import { ComplexTableProps } from './table';
import { getRowKey } from './utils';

import S from './table.module.less';

export interface CustomExpandableConfig<T>
  extends Omit<AntdExpandableConfig<T>, 'onExpand'> {
  onExpand?: (expanded: boolean, record: T, expandedKey: Key) => void;
}

export function useExpandable<T extends object>(
  props: ComplexTableProps<T>,
): AntdTableProps<T>['expandable'] {
  const { dataSource, expandable } = props;
  const { expandedRowKeys } = expandable ?? {};

  /**
   * 总是处于展开状态的那些行
   *
   * 那些 `extra` 有返回内容的行（即返回的不是 `false`，`null`，`undefined` 之类的空内容）需要总是为展开的状态，以便能够通过 expandable row 来展示 extra 的内容
   *
   * `extra` 的实现原理是通过 antd table 的 expandable row 来模拟实现的
   *
   * 即 `extra` 的内容是放在 expandable row 中，然后通过内部根据 `expandedRowKeys` 的状态来切换 expandable 的图标状态来模拟展开/收起
   */
  const alwaysExpandedRowKeys = useMemo(() => {
    if (!props.extra) {
      return;
    }

    if (!props.rowKey) {
      return;
    }

    const keys: Key[] = [];

    for (const item of dataSource ?? []) {
      const rowKey = getRowKey(props.rowKey, item);

      if (!props.extra(item)) {
        continue;
      }

      keys.push(rowKey);
    }

    return keys;
  }, [dataSource]);

  /**
   * 合并后的 expandedKeys
   *
   * 除了那些总是为展开状态的行（即 extra 有返回内容的行）外，还有外部那些用户控制的展开行
   */
  const mergedExpandedRowKeys = useMemo(() => {
    return Array.from(
      new Set<Key>([
        ...(alwaysExpandedRowKeys ?? []),
        ...(expandedRowKeys ?? []),
      ]),
    );
  }, [alwaysExpandedRowKeys, expandedRowKeys]);

  /**
   * 获取当前的用户实际看到的展开状态，而不是内容合成后的行展开状态
   *
   * 即通过外部传进来的 `expandedRowKeys` 确定当前行的展开状态
   */
  const getExpandedStatus = (record: T) => {
    const key = getRowKey(props.rowKey, record);

    return !!props.expandable?.expandedRowKeys?.includes(key);
  };

  /**
   * 代理 onExpand 回调
   *
   * @param expanded 展开状态
   * @param record 当前行的数据
   */
  const onExpand = (_expanded: boolean, record: T) => {
    const key = getRowKey(props.rowKey, record);
    const isExpanded = getExpandedStatus(record);

    props.expandable?.onExpand?.(!isExpanded, record, key);
  };

  if (!props.expandable && !props.extra) {
    return;
  }

  if (props.expandable == null) {
    return;
  }

  return {
    ...props.expandable,
    expandedRowClassName: () => clsx('override', props.className),
    /**
     * 这里合成的展开状态模拟展开/关闭的图标
     */
    expandIcon: (iconProps) => {
      const isExpanded = getExpandedStatus(iconProps.record);

      return (
        <ExpandIcon
          expanded={isExpanded}
          onClick={(e) => {
            iconProps.onExpand?.(iconProps.record, e);
          }}
        />
      );
    },
    expandedRowKeys: mergedExpandedRowKeys,
    onExpand: onExpand,
    /**
     * 代理 `expandedRowRender` 方法，来合适需要的展开状态来控制内容的展示
     *
     * @param record 当前行数据
     * @param index 索引
     * @param indent 缩紧
     * @param expanded 是否展开
     * @returns
     */
    expandedRowRender: (record, index, indent) => {
      const isExpanded = getExpandedStatus(record);
      const note = props.extra?.(record);

      return (
        <>
          {note && <div className={S.extra}>{note}</div>}
          {isExpanded &&
            props.expandable?.expandedRowRender?.(
              record,
              index,
              indent,
              isExpanded,
            )}
          {}
        </>
      );
    },
  };
}
