import { BooleanNumber } from '@/common/types';

/**
 * 文件上传模块
 */
export enum ModuleType {
  sku = 'sku',
  spu = 'spu',
  /**
   * 订单
   */
  order = 'order',
  /**
   * 用户
   */
  user = 'user',
  /**
   * 洗头
   */
  system = 'system',
  /**
   * 物流
   */
  logistic = 'logistic',
  /**
   * 供应链
   */
  supplier = 'supplier',
  /**
   * 供应链合同
   */
  supplier_contract = 'supplier_contract',
  /**
   * 采购单
   */
  purchase_order = 'purchase_order',
  /**
   * 采购付款凭证
   */
  purchase_payment_voucher = 'purchase_payment_voucher',
  /**
   * 仓库
   */
  warehouse = 'warehouse',
}
/**
 * 文件签名请求参数
 */
export interface FileSignBody {
  resource?: string;
  mime_type?: string;
  path?: string;
  group_id?: string;
  is_hidden?: 0 | 1;
}

/**
 * 文件保存请求参数
 */
export interface FileSaveBody {
  file_content_type?: 'image' | 'video' | 'file-list-item';
  mime_type?: string;
  module_type?: ModuleType;
  file_name?: string;
  group_id?: number;
  is_hidden?: 0 | 1;
  key?: string;
  url?: string;
}

export interface GetFilesParams {
  search_name: string;
  per_page: number;
  page: number;
}

export interface ImageCenterItemUserMeta {
  email: string;
  id: number;
  name: string;
  phone: string;
}

export interface ImageCenterItem {
  id: number;
  group_id: number;
  title: string;
  path: string;
  cover_url: string;
  mime_type: string;
  type: string;
  size: string;
  width: number;
  height: number;
  hash: string;
  meta: string;
  is_hidden: number;
  creator_user_id: number;
  creator_user_meta: ImageCenterItemUserMeta;
  created_at_gmt: string;
  updated_at_gmt: string;
  user_name: string;
  group_title: string;
}

/**
 * 图片上传结果
 */
export interface ImageUploadSaveResponse {
  id: number;
  group_id: number;
  title: string;
  path: string;
  cover_url: string;
  mime_type: string;
  type: string;
  size: number;
  width: number;
  height: number;
  hash: string;
  meta: string;
  is_hidden: BooleanNumber;
  creator_user_id: number;
  created_at_gmt: string;
  updated_at_gmt: string;
  creator_user_meta: ImageCenterItemUserMeta;
}
