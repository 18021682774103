import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import type { SortOrder } from 'antd/es/table/interface';
import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';

export interface TableHeaderTitleProps {
  sortable?: boolean;
  order?: SortOrder;
  onSort?: () => void;
}

export const HeaderTitle: FC<PropsWithChildren<TableHeaderTitleProps>> = (
  props,
) => {
  return (
    <div
      className={clsx('ant-table-column-sorters after:content-none', {
        'ant-table-column-has-sorters': props.sortable,
        'cursor-pointer': props.sortable,
      })}
      onClick={props.sortable ? props.onSort : void 0}
    >
      <div className="ant-table-column-title">
        <div>{props.children}</div>
      </div>
      {props.sortable === true && (
        <div
          className={clsx(
            'ant-table-column-sorter',
            'ant-table-column-sorter-full',
          )}
        >
          <div className="ant-table-column-sorter-inner">
            <CaretUpOutlined
              className={clsx('ant-table-column-sorter-up', {
                active: props.order === 'ascend',
              })}
            />
            <CaretDownOutlined
              className={clsx('ant-table-column-sorter-down', {
                active: props.order === 'descend',
              })}
            />
          </div>
        </div>
      )}
    </div>
  );
};
