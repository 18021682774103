import { ImageUploadSaveResponse, ModuleType } from '@/features/file';
import { beforeUploadImage, customUploadRequest } from '@/utils/upload';
import { Image, Upload, UploadProps } from 'antd';
import { UploadFileStatus } from 'antd/es/upload/interface';
import { forwardRef, useMemo, useState } from 'react';
import { ImageValue, UploadImageFile } from './types';
import { toImageValue, toImageValues } from './utils';

export interface UploaderControlProps
  extends Pick<UploadProps, 'listType' | 'children' | 'maxCount' | 'disabled'> {
  value?: ImageValue[];
  onChange?: (value: ImageValue[]) => void;
  moduleType: ModuleType;
}

export const UploaderControl = forwardRef<HTMLDivElement, UploaderControlProps>(
  (props, ref) => {
    const {
      value: _value,
      onChange: _onChange,
      moduleType,
      ...restUploadProps
    } = props;

    const [visible, setVisible] = useState(false);
    const [current, setCurrent] = useState(0);

    const fileList = useMemo<UploadImageFile[]>(() => {
      if (!props.value) {
        return [];
      }

      return props.value?.map((item) => ({
        uid: item.uid,
        url: item.url,
        name: item.name ?? item.id + '',
        percent: item.percent,
        status: item.status as UploadFileStatus,
        thumbUrl: item.thumbUrl,
        response:
          item.id && item.url
            ? {
                id: item.id,
                cover_url: item.url,
              }
            : void 0,
      }));
    }, [props.value]);

    const onChange: UploadProps<ImageUploadSaveResponse>['onChange'] = (
      info,
    ) => {
      if (info.file.status !== 'done') {
        props.onChange?.(toImageValues(info.fileList));
        return;
      }

      if (info.file.status === 'done') {
        if (!info.file.response) {
          props.onChange?.(toImageValues(info.fileList));
          return;
        }

        const { response } = info.file;

        props.onChange?.(
          info.fileList.map<ImageValue>((item) => {
            if (item.uid !== info.file.uid) {
              return toImageValue(item);
            }

            return {
              id: +response.id,
              uid: item.uid,
              url: response.cover_url,
              name: item.name,
              percent: item.percent,
              status: item.status,
              thumbUrl: response.cover_url,
            };
          }),
        );
      }
    };

    const onRemove: UploadProps['onRemove'] = (file) => {
      const index = (fileList ?? []).findIndex((item) => item.uid === file.uid);

      if (index === -1) {
        return;
      }

      const fileListCopy = [...(props.value ?? [])];
      fileListCopy.splice(index, 1);
      props.onChange?.(fileListCopy);
    };

    const onPreview: UploadProps['onPreview'] = (file) => {
      const index = (fileList ?? []).findIndex((item) => item.uid === file.uid);

      if (index === -1) {
        return;
      }

      setVisible(true);
      setCurrent(index);
    };

    return (
      <>
        <Upload
          fileList={fileList}
          data={{ moduleType }}
          customRequest={customUploadRequest}
          beforeUpload={beforeUploadImage}
          onPreview={onPreview}
          onChange={onChange}
          onRemove={onRemove}
          {...restUploadProps}
        />
        <div ref={ref} className="hidden">
          <Image.PreviewGroup
            preview={{
              visible,
              current,
              onVisibleChange: (value) => setVisible(value),
            }}
          >
            {fileList?.map((file) => (
              <Image key={file.uid} src={file.url} />
            ))}
          </Image.PreviewGroup>
        </div>
      </>
    );
  },
);
