import { BizError } from '../errors';

export interface OnErrorCallback {
  (error: unknown): void;
}

export class ErrorHandler {
  private errors: unknown[] = [];

  private onErrorCallbacks = new Set<OnErrorCallback>();

  onError(cb: OnErrorCallback) {
    this.onErrorCallbacks.add(cb);

    return () => {
      this.onErrorCallbacks.delete(cb);
    };
  }

  report(error: unknown) {
    this.errors.push(error);

    setTimeout(() => {
      this.handle();
    });
  }

  private handle() {
    while (this.errors.length) {
      // FIFO
      const error = this.errors.shift();

      if (error instanceof BizError && error.isHandled) {
        continue;
      }

      for (const callback of this.onErrorCallbacks) {
        callback(error);
      }
    }
  }
}

export const errorHandler = new ErrorHandler();
