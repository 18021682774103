import { useMutation } from '@umijs/max';
import { fileService } from './file.service';

/**
 * 上传指定 URL 的图片
 * @returns
 */
export const useSaveImageByURL = () => {
  return useMutation({
    mutationFn: async (url: string) => {
      return fileService.upload(url);
    },
  });
};
