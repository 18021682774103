import { TablePaginationConfig, TableProps } from 'antd';
import { forwardRef, ReactElement, Ref } from 'react';

import { FowardedTable } from './table';

export interface PagingTableProps<TRecord> extends TableProps<TRecord> {
  pagination: TablePaginationConfig;
}

/**
 * 分页表格，提供了默认的分页属性
 * @param props 表格属性，必须提供分页参数
 * @param ref
 * @returns
 */
function PagingTable<RecordType extends object = any>(
  props: TableProps<RecordType>,
  ref: Ref<HTMLDivElement>,
) {
  const pagination: TableProps<RecordType>['pagination'] =
    typeof props.pagination === 'boolean'
      ? props.pagination
      : {
          showTotal: (total) => `共 ${total} 条`,
          pageSizeOptions: [100, 200, 500],
          showQuickJumper: true,
          showSizeChanger: true,
          ...props.pagination,
        };

  return <FowardedTable ref={ref} {...props} pagination={pagination} />;
}

/**
 * 使用 forwardRef 把 ref 传递下去
 */
export const FowardedPagingTable = forwardRef(PagingTable) as <
  RecordType extends object = any,
>(
  props: TableProps<RecordType> & { ref?: Ref<HTMLDivElement> },
) => ReactElement;
