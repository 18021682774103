import { RefSelectProps } from 'antd';
import { forwardRef } from 'react';

import { Select, SelectProps } from '@/antd';
import { BooleanNumber } from '@/common/types';

const options: SelectProps['options'] = [
  {
    label: '是',
    value: BooleanNumber.True,
  },
  {
    label: '否',
    value: BooleanNumber.False,
  },
];

export const BooleanNumberSelect = forwardRef<RefSelectProps, SelectProps>(
  (props, ref) => {
    return <Select ref={ref} options={options} {...props} />;
  },
);
