import { BizError, errorHandler, UnauthenticatedError } from '@/core';
import { useLogout } from '@/features/user';
import { useLocation, useNavigate } from '@umijs/max';
import { message } from 'antd';
import { throttle } from 'lodash-es';
import { useCallback, useEffect, useMemo } from 'react';

export const useErrorHandler = () => {
  const navigate = useNavigate();
  const logout = useLogout();
  const location = useLocation();

  /**
   * 处理认证异常的时候使用防抖来避免重复提示错误信息
   */
  const handleUnthenticated = useMemo(() => {
    return throttle(
      () => {
        message.error('身份认证失效，请重新登录');
      },
      300,
      {
        trailing: false,
      },
    );
  }, []);

  const handleError = useCallback(
    (error: unknown) => {
      if (error instanceof BizError) {
        /**
         * 忽略已被处理过的错误
         */
        if (error.isHandled) {
          return;
        }

        if (error instanceof UnauthenticatedError) {
          /**
           * 如果用户已被踢出就不再处理认证失败的错误
           */
          if (location.pathname === '/user/login') {
            return;
          }

          logout();
          navigate(`/user/login`, { replace: true });
          handleUnthenticated();
          return;
        }

        message.error(error.message);
        return;
      }

      // 除了 BizError 这种自定义错误会使用其自带的 message 展示错误内容外，其他无法识别的错
      // 误均视为未知错误这里仅弹出一个固定的错误提示来告知用户当前的操作出现了异常
      message.error('发生未知的错误');
    },
    [handleUnthenticated, location, logout, navigate],
  );

  useEffect(() => {
    const disposable = errorHandler.onError(handleError);

    return disposable;
  }, [handleError]);
};
