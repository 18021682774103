import { FC, PropsWithChildren, ReactNode } from 'react';
import { useAuthority } from '../workspace.hook';

export interface AuthorityProps {
  name: string | string[];
  fallback?: ReactNode;
}

export const Authority: FC<PropsWithChildren<AuthorityProps>> = (props) => {
  const isGranted = useAuthority(props.name);

  return isGranted ? (
    <>{props.children}</>
  ) : props.fallback ? (
    <>{props.fallback}</>
  ) : null;
};
