import { createContext, useContext } from 'react';
import { OverlayRef } from './overlay-ref';

const OverlayRefContext = createContext<OverlayRef<unknown> | null>(null);

export const OverlayRefProvider = OverlayRefContext.Provider;

export function useOverlayRef<TResult>(optional?: false): OverlayRef<TResult>;
// prettier-ignore
export function useOverlayRef<TResult>(optional: boolean): OverlayRef<TResult> | null;
export function useOverlayRef<TResult>(
  optional = false,
): OverlayRef<TResult> | null {
  const overlayRef = useContext(OverlayRefContext);

  if (!overlayRef && !optional) {
    throw new Error('未找到 Overlay，请检查组件是否在 Overlay Hook 中使用');
  }

  return overlayRef as OverlayRef<TResult>;
}
