import { get } from 'lodash-es';
import { ReactNode } from 'react';

import { TableColumnType } from 'antd';
import { SortOrder } from 'antd/es/table/interface';
import {
  ColumnGroupConfig,
  ColumnsConfig,
  ColumnTypeExtend,
} from './column.types';
import { EmptyCell } from './empty-cell';
import { HeaderTitle } from './header-title';
import { Sort } from './table';
import { isEmptyContent, tryGetKey } from './utils';

import S from './column-builder.module.less';

export const sortDirections: SortOrder[] = ['ascend', 'descend'];

export function buildColumnGroup<T>(
  group: ColumnGroupConfig<T>,
  sort?: Sort,
  onSort?: (sort: Sort) => void,
): ColumnTypeExtend<T> {
  const { items, ...restConfig } = group;

  const column: ColumnTypeExtend<T> = { ...restConfig };

  column.title = (props) => {
    return items.map((item) => {
      const key = tryGetKey(item);
      let title: ReactNode | undefined;

      if (!item.title) {
        title = item.dataIndex;
      } else if (typeof item.title === 'string') {
        title = item.title;
      } else if (typeof item.title === 'function') {
        title = item.title(props);
      } else {
        title = item.title;
      }

      return (
        <HeaderTitle
          key={key}
          sortable={!!item.sorter}
          order={sort?.field === key ? sort?.order : void 0}
          onSort={() => {
            if (!onSort) {
              return;
            }

            let sortOrder: SortOrder = 'ascend';

            if (key === sort?.field) {
              const index =
                sort.order == null ? -1 : sortDirections.indexOf(sort.order);
              sortOrder = sortDirections[index + 1];
            }

            onSort({
              order: sortOrder,
              field: key,
            });
          }}
        >
          {title}
        </HeaderTitle>
      );
    });
  };

  column.render = (_, record, index) => {
    const nodes: ReactNode[] = [];

    for (const item of items) {
      const key = tryGetKey(item);

      let content: ReactNode;

      const value = get(record, item.dataIndex ?? []);

      if (item.tryRender) {
        if (isEmptyContent(value)) {
          content = <EmptyCell />;
        } else {
          content = item.tryRender(value, record, index);
        }
      } else if (item.render) {
        content = item.render(value, record, index);
      } else {
        content = value;
      }

      nodes.push(
        <li key={key}>{isEmptyContent(content) ? <EmptyCell /> : content}</li>,
      );
    }

    return <ul className={S['cell-list']}>{nodes}</ul>;
  };

  return column;
}

export function buildColumn<T>(
  columns: ColumnsConfig<T>,
  sort?: Sort,
  onSort?: (sort: Sort) => void,
): ColumnTypeExtend<T>[] {
  return columns.map((column) => {
    if ('items' in column) {
      return buildColumnGroup(column, sort, onSort);
    }

    return {
      ...column,
      showSorterTooltip: false,
      sortOrder: setSortOrder(sort, column.dataIndex),
    };
  });
}

function setSortOrder(
  sort: Sort | undefined,
  dataIndex?: TableColumnType<unknown>['dataIndex'],
) {
  if (!sort) {
    return void 0;
  }

  const { field, order } = sort;

  return field === dataIndex ? order : null;
}
