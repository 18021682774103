import { Table as AntdTable } from 'antd';
import { ComplexTable } from './complex-table';
import { FowardedPagingTable } from './paging-table';
import { FowardedTable } from './table';

export type {
  TableColumnGroupType,
  TableColumnProps,
  TableColumnsType,
  TableColumnType,
  TablePaginationConfig,
  TableProps,
} from 'antd';
export { EmptyCell } from './complex-table';
export type {
  ActionColumn,
  CellItem,
  CellRowProps,
  ColumnCustomProps,
  ColumnGroupConfig,
  ColumnItemConfig,
  ColumnsConfig,
  ColumnTypeExtend,
  ComplexTableProps,
  Sort,
} from './complex-table';
export type { PagingTableProps } from './paging-table';

export const Table = FowardedTable as unknown as typeof AntdTable & {
  /**
   * 分页表格，提供了默认的分页属性
   */
  Paging: typeof FowardedPagingTable;
  /**
   * 复杂表格
   *
   * 提供了列字段堆叠，自定义列等功能
   */
  Complex: typeof ComplexTable;
  SELECTION_COLUMN: typeof AntdTable.SELECTION_COLUMN;
  EXPAND_COLUMN: typeof AntdTable.EXPAND_COLUMN;
  SELECTION_ALL: typeof AntdTable.SELECTION_ALL;
  SELECTION_INVERT: typeof AntdTable.SELECTION_INVERT;
  SELECTION_NONE: typeof AntdTable.SELECTION_NONE;
  Column: typeof AntdTable.Column;
  ColumnGroup: typeof AntdTable.ColumnGroup;
  Summary: typeof AntdTable.Summary;
};

Table.Paging = FowardedPagingTable;
Table.Complex = ComplexTable;
Table.SELECTION_COLUMN = AntdTable.SELECTION_COLUMN;
Table.EXPAND_COLUMN = AntdTable.EXPAND_COLUMN;
Table.SELECTION_ALL = AntdTable.SELECTION_ALL;
Table.SELECTION_INVERT = AntdTable.SELECTION_INVERT;
Table.SELECTION_NONE = AntdTable.SELECTION_NONE;
Table.Column = AntdTable.Column;
Table.ColumnGroup = AntdTable.ColumnGroup;
Table.Summary = AntdTable.Summary;
