import axios from 'axios';
import {
  CaptchaDto,
  ChangePasswordKeyDto,
  OneTimePasswordDto,
  PasswordCredentialDto,
  ResetPasswordDto,
  SendOneTimePasswordDto,
  TokenDto,
  UserProfile,
  UserRole,
  VerifyOneTimePasswordDto,
} from './user.dto';
import { ChangePasswordEntity } from './user.entity';

export class UserService {
  private TOKEN_KEY = 'token';

  /**
   * 登录
   * @param dto 用户密码凭证
   * @returns
   */
  async login(dto: PasswordCredentialDto) {
    const { data } = await axios.post<TokenDto>('api/auth/login', dto);
    return data;
  }

  /**
   * 获取用户信息
   *
   * @returns 用户信息
   */
  async getUserProfile() {
    const { data } = await axios.post<UserProfile>('api/me');

    return data;
  }

  async changePassword(data: ChangePasswordEntity) {
    await axios.put('api/password', {
      password_old: data.password,
      password: data.newPassword,
      // TODO: 删除该字段，不是必须的，后端会去掉这个字段的校验
      password_confirm: data.newPassword,
    });
  }

  async logout() {
    if (!this.hasToken()) {
      return;
    }

    await axios.post('api/auth/logout');
  }

  /**
   * 获取用户下所有角色列表
   */
  async getRoles(): Promise<UserRole[]> {
    const { data } = await axios.get<UserRole[]>('api/roles');
    return data;
  }

  /**
   * 获取验证码图片
   * @returns 验证码图片信息
   */
  async getCaptcha() {
    const { data } = await axios.get<CaptchaDto>('api/captcha');
    return data;
  }

  /**
   * 发送短信验证码
   * @param dto 验证码发送参数
   * @returns
   */
  async sendOneTimePassword(dto: SendOneTimePasswordDto) {
    const { data } = await axios.post<OneTimePasswordDto>(
      'api/auth/sms/password',
      dto,
    );
    return data;
  }

  /**
   * 验证短信验证码
   * @param dto 验证短信验证码的参数
   * @returns
   */
  async verifyOneTimePassword(dto: VerifyOneTimePasswordDto) {
    const { data } = await axios.post<ChangePasswordKeyDto>(
      'api/auth/sms/confirm/password',
      dto,
    );
    return data;
  }

  /**
   * 忘记密码后重置密码
   */
  async resetPassword(dto: ResetPasswordDto) {
    await axios.post<void>('api/users/password', dto);
  }

  hasToken(): boolean {
    return !!this.getToken();
  }

  setToken(token: string): void {
    localStorage.setItem(this.TOKEN_KEY, token);
  }

  getToken(): string {
    return localStorage.getItem(this.TOKEN_KEY) || '';
  }

  clearToken() {
    this.setToken('');
  }
}

export const userService = new UserService();
