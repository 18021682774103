import { matchPath } from '@umijs/max';
import { MenuConfig, MenuItemAware, SubMenu } from './menu';

/**
 * 计算路径片段的长度
 * @param path 路径
 * @returns
 */
export function calculateSegmentLength(path: string) {
  return path.split('/').filter(Boolean).length;
}

export function isMatchPath(menu: MenuConfig, pathname: string) {
  if (!('path' in menu)) {
    return false;
  }

  if (!menu.path) {
    return false;
  }

  const match = matchPath({ path: menu.path, end: false }, pathname);

  return !!match;
}

export function getMatchedMenuItem(menus: MenuItemAware[], pathname: string) {
  return menus.filter((menu) => {
    if (!('path' in menu)) {
      return false;
    }

    return isMatchPath(menu, pathname);
  });
}

/**
 * 根据路径片段的长度选择最匹配的菜单项
 *
 * 路径片段越长说明越匹配
 *
 * @param menus 菜单项
 * @returns
 */
export function getBestMatch(
  menus: MenuItemAware[],
): MenuItemAware | undefined {
  if (!menus.length) {
    return;
  }

  if (menus.length > 1) {
    menus.sort((a, b) => {
      return (
        calculateSegmentLength(b.path ?? '') -
        calculateSegmentLength(a.path ?? '')
      );
    });
  }

  return menus.at(0);
}

export function* generateActiveMenu(
  menus: MenuItemAware[],
  pathname: string,
): Generator<MenuConfig | SubMenu> {
  const matches = getMatchedMenuItem(menus, pathname);

  const match = getBestMatch(matches);

  if (!match) {
    return;
  }

  yield match;

  if (!('children' in match)) {
    return;
  }

  if (!match.children) {
    return;
  }

  yield* generateActiveMenu(match.children, pathname);
}

export function findMenuByKey(
  menus: MenuConfig[],
  key: string,
): MenuConfig | undefined {
  for (const menu of menus) {
    if (menu.key === key) {
      return menu;
    }

    if ('children' in menu && menu.children) {
      const result = findMenuByKey(menu.children, key);

      if (result) {
        return result;
      }
    }
  }
}
