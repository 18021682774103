import { Link, useAppData } from '@umijs/max';
import type {
  MenuItemGroupType,
  MenuItemType,
  SubMenuType,
} from 'antd/es/menu/hooks/useItems';
import { atom, useAtom } from 'jotai';
import { cloneDeep } from 'lodash-es';

import { MenuIcon } from '@/components';

export type MenuItemAware = {
  path?: string;
  authorities?: string[];
  children?: MenuItemAware[];
} & MenuConfig;

export interface MenuItem extends MenuItemType {
  path?: string;
}

export interface SubMenu extends SubMenuType {
  path?: string;
  children: MenuConfig[];
}

export interface MenuItemGroup extends MenuItemGroupType {
  children: MenuConfig[];
}

export type AppData = ReturnType<typeof useAppData>;
export type RouteMap = AppData['routes'];
export type Route = RouteMap[string];

export interface RouteTreeNode extends Route {
  children?: RouteTreeNode[];
}

export type MenuConfig = MenuItem | SubMenu | MenuItemGroup;

/**
 * 是否布局组件
 *
 * @param id 路由 ID
 * @returns
 */
function isLayout(id: string) {
  return id === '@@/global-layout';
}

/**
 * 将 Umi 的路由表还原成树形结构
 *
 * @param routeMap Umi 的路由表
 * @returns
 */
export function rebuildRouteTree(routeMap: RouteMap) {
  const clonedRouteMap: { [id: string]: RouteTreeNode } = cloneDeep(routeMap);
  const routeArray = Object.values(clonedRouteMap);
  const root: RouteTreeNode[] = [];

  for (const route of routeArray) {
    // 忽略布局路由
    if (isLayout(route.id)) {
      continue;
    }

    if (!route.parentId) {
      root.push(route);
      continue;
    }

    if (isLayout(route.parentId)) {
      root.push(route);
      continue;
    }

    const parentRoute = clonedRouteMap[route.parentId];

    if (!parentRoute) {
      continue;
    }

    parentRoute.children ??= [];
    parentRoute.children.push(route);
  }

  return root;
}

export function* generateMenus(
  routes: any[],
  parentRoute?: MenuItemAware,
): Generator<MenuItemAware> {
  for (let route of routes) {
    /**
     * 如果是 wrapper 的话，直接取其子元素的第一个路由配置就是原始的那个项
     */
    if (route.isWrapper) {
      const [originalRoute] = route.children;

      // 由于多了一层 wrapper 所以，多了一个层级，并且原路由配置中的 path 会被 wrapper 拿走
      // 所以这里要把 path 拿回来，并且重新设置一下 parentId，来还原原来的层级
      // TODO: 其实 wrapper 可以同时设置多个（是个数组），这里没有处理这种情况
      route = {
        ...originalRoute,
        path: route.path,
        parentId: route.parentId,
      };
    }

    const basePath = parentRoute?.path ?? '/';

    // 如果路由的路径为绝对路径则直接将其作为路由路径
    // 否则，当前路由为相对路径，则需要将上级的路由路径作为基础路径进行拼接
    const routePath = route.path?.startsWith('/')
      ? route.path
      : [basePath, route.path].filter(Boolean).join('/');

    let menuItem: MenuItemAware | undefined;

    // 路由配置中有 menu 的说明要作为菜单项
    if ('menu' in route) {
      const icon =
        typeof route.menu === 'boolean' ? (
          void 0
        ) : (
          <MenuIcon type={route.menu.icon} />
        );

      if (route.menu) {
        menuItem = {
          // 当前路径与上级路径相同的，增加 $ 作为后缀，避免 key 冲突
          key: routePath === basePath ? routePath + '$' : routePath,
          label: route.title,
          path: routePath,
          authorities: [parentRoute?.authorities ?? []]
            .concat([route.authority])
            .flat()
            .filter(Boolean),
          icon,
        };
      }
    }

    // 菜单项构造完成且没有子菜单的，说明为末级菜单，不要继续处理
    if (menuItem && !route.children) {
      menuItem.label = <Link to={routePath}>{route.title}</Link>;
      yield menuItem;
      continue;
    }

    // 既没有菜单项，也没有子菜单的，不要继续处理了
    if (!route.children) {
      continue;
    }

    // 生成子菜单
    const menusGenerator = generateMenus(route.children, menuItem);

    // 如果没有菜单项，则将则直接使用子菜单作为这一级的菜单项
    if (!menuItem) {
      yield* menusGenerator;
      continue;
    }

    // 否则为当前菜单项添加子菜单
    const children = Array.from(menusGenerator);

    if (children.length) {
      (menuItem as SubMenuType | MenuItemGroupType).children = children;
    } else {
      menuItem.label = <Link to={routePath}>{route.title}</Link>;
    }

    yield menuItem;
  }
}

// atom: 原子的意思。一个Atom代表一个状态，使用atom函数创建一个Atom，需要传入一个参数作为初始值。
// useAtom 函数接受一个参数，参数值为 一个 Atom 返回值是一个数组
// 数组第一个值是 Atom 存储的值，第二个值是更新 Atom 值的函数

export const menusAtom = atom<MenuItemAware[]>([]);

export const useMenus = () => {
  return useAtom(menusAtom);
};
