import axios, { AxiosRequestConfig } from 'axios';

export interface FilesSignResponse {
  access_id: string;
  expire: number;
  host: string;
  path: string;
  policy: string;
  signature: string;
}

async function getFileSign(
  formData: FormData,
  options?: AxiosRequestConfig,
): Promise<FilesSignResponse> {
  const { data } = await axios.post<FilesSignResponse>(
    '/api/files/sign',
    formData,
    options,
  );

  return data;
}

async function uploadFiles(
  url: string,
  formData: FormData,
  options?: AxiosRequestConfig,
) {
  const { data } = await axios.post(url, formData, options);

  return data;
}

async function saveFiles(formData: FormData, options?: AxiosRequestConfig) {
  const { data } = await axios.post('/api/files', formData, options);

  return data;
}

async function deleteFiles(
  params: { id: string },
  options?: AxiosRequestConfig,
) {
  const { id } = params;
  const { data } = await axios.delete(`/api/files/${id}`, options);

  return data;
}

async function getFiles(options?: AxiosRequestConfig) {
  const { data } = await axios.get('/api/images/sku', options);
  return data;
}

export { getFileSign, uploadFiles, saveFiles, deleteFiles, getFiles };
