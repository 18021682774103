import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import type { IProps } from '@tinymce/tinymce-react/lib/es2015/main/ts/components/Editor';
import { IEvents } from '@tinymce/tinymce-react/lib/es2015/main/ts/Events';
import useMergedState from 'rc-util/lib/hooks/useMergedState';

export type TinyMceEditorProps = Partial<IProps> &
  Partial<IEvents> & {
    onChange?: IProps['onEditorChange'];
  };

export function TinyMceEditor(props: TinyMceEditorProps) {
  const { init, onChange, value, onEditorChange, ...restProps } = props;
  const [editorValue, setEditorValue] = useMergedState(props.initialValue, {
    value: value,
  });

  const handleUpdate: IProps['onEditorChange'] = (value, editor) => {
    if (value === undefined) {
      setEditorValue(value);
    }

    if (typeof onChange === 'function') {
      onChange(value, editor);
    }
    if (typeof onEditorChange === 'function') {
      onEditorChange(value, editor);
    }
  };

  return (
    <Editor
      init={{
        language: 'zh-CN',
        language_url: '/src/components/tiny-mce-editor/zh-Hans.js',
        ...init,
      }}
      apiKey="0fbc1e993d196pi43nhknnh7zfccdwki8n5mgys4tsy03qb2"
      onEditorChange={(value, editor) => {
        handleUpdate(value, editor);
      }}
      value={editorValue}
      {...restProps}
    />
  );
}
