import { RefSelectProps } from 'antd';
import { BaseOptionType, DefaultOptionType } from 'antd/es/select';
import { forwardRef, ReactElement, Ref } from 'react';

import { Select, SelectProps } from '@/antd';

export interface NullableSelectProps<
  ValueType = any,
  OptionType extends DefaultOptionType | BaseOptionType = DefaultOptionType,
> extends SelectProps<ValueType, OptionType> {
  /**
   * 在执行清空操作的时候将值设置为 `null` 而非 `undefined`
   *
   * 后端的接口默认情况下将 `undefined` 视为未修改，需要使用 `null` 来表示空值
   */
  clearToNull?: boolean;
}

export const NullableSelect = forwardRef<RefSelectProps, NullableSelectProps>(
  (props, ref) => {
    const { clearToNull = false, ...restProps } = props;

    return (
      <Select
        ref={ref}
        {...restProps}
        onChange={(value, option) => {
          if (value === undefined && clearToNull) {
            props.onChange?.(null, option);
            return;
          }

          props.onChange?.(value, option);
        }}
      />
    );
  },
) as <
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>(
  props: NullableSelectProps<ValueType, OptionType> & {
    ref?: Ref<RefSelectProps>;
  },
) => ReactElement;
