import { setLocale } from 'yup';

export interface BuildInMeta {
  /**
   * 单位
   *
   * 数组中会用到
   *
   * 默认为项
   */
  unit?: string;

  /**
   * 是否为选择框
   *
   * 默认提示语为请输入，如果 `select` 为 `true` 则提示语为请选择
   */
  select?: boolean;
}

class TypeMap {
  private static types = new Map([
    ['mixed', '任意'],
    ['string', '字符串'],
    ['number', '数值'],
    ['date', '日期'],
    ['object', '对象'],
    ['array', '数组'],
    ['boolean', '布尔'],
    ['tuple', '元组'],
  ]);

  static nameOf(type: string): string {
    const name = this.types.get(type);

    if (!name) {
      throw new Error('类型未定义，请检查类型是否遗漏');
    }

    return name;
  }
}

export interface MessageParams {
  label: string;
  path: string;
}

export function generateLabel({ label, path }: MessageParams) {
  if (label) {
    return label;
  }

  // 如果 label 不存在则以 path 构造模版字符串
  return `<%= ${path} %>`;
}

const label = generateLabel;

export function setupYup() {
  setLocale({
    array: {
      min: (p) => {
        if (p.min === 1) {
          return `请选择${label(p)}`;
        }

        return `至少包含 ${p.min} ${p.spec.meta.unit ?? '项'}${label(p)}`;
      },
      max: (p) => `至多包含 ${p.max} ${p.spec.meta.unit ?? '项'}${label(p)}`,
      length: (p) =>
        `应当包含 ${p.length} ${p.spec.meta.unit ?? '项'}${label(p)}`,
    },
    boolean: {
      isValue: (p) => `${label(p)}应当为 ${p.value}`,
    },
    date: {
      min: (p) => `${label(p)}不得早于 ${p.min}`,
      max: (p) => `${label(p)}不得晚于 ${p.max}`,
    },
    mixed: {
      default: (p) => `${label(p)} 值无效`,
      required: (p) => {
        if (p.spec.meta?.select) {
          return `请选择${label(p)}`;
        }

        return `请输入${label(p)}`;
      },
      oneOf: (p) => `${label(p)} 应当是 ${p.values} 其中之一`,
      notOneOf: (p) => `${label(p)} 不得是 ${p.values} 其中之一`,
      notType: (p) => {
        const msg = `${label(p)}应当是一个「${TypeMap.nameOf(p.type)}」类型。${
          p.value === null
            ? '\n如果想将 「null」 作为空值，可以使用 `.nullable()` 来设置校验规则。'
            : ''
        }`;

        return msg;
      },
      defined: (p) => `${label(p)} 的值未定义`,
      notNull: (p) =>
        `${label(
          p,
        )}不能为「null」，如果想将 「null」 作为空值，可以使用 \`.nullable()\` 来设置校验规则`,
    },
    number: {
      min: (p) => `${label(p)}不小于 ${p.min}`,
      max: (p) => `${label(p)}不大于 ${p.max}`,
      moreThan: (p) => `${label(p)}应大于 ${p.more}`,
      lessThan: (p) => `${label(p)}应小于 ${p.less}`,
      positive: '请输入正数',
      negative: '请输入负数',
      integer: '请输入整数',
    },
    object: {
      noUnknown: ({ path }) => `${path} field has unspecified keys`,
    },
    string: {
      length: (p) => `请输入 ${p.length} 位${label(p)}`,
      min: (p) => `${label(p)}的内容不得少于 ${p.min} 个字符`,
      max: (p) => `${label(p)}的内容不得多于 ${p.max} 个字符`,
      matches: (p) => `${label(p)}的内容与模式[${p.regex}]不匹配`,
      email: '请输入有效的电子邮箱',
      url: '请输入有效的 URL',
      uuid: '请输入有效的 UUID',
      trim: (p) => `${label(p)}的首尾不得包含空格`,
      lowercase: '请使用小写字母',
      uppercase: '请使用大写字母',
    },
  });
}
