import { useEffect, useRef, useState } from 'react';
import { ColumnsConfig } from './column.types';
import { getSortStoreKey, restoreSortKeys, tryGetKey } from './utils';

export function useSortedColumns<T>(
  id: string,
  columns: ColumnsConfig<T> = [],
) {
  const [sortedItems, setSortedItems] = useState<ColumnsConfig<T>>([]);
  const isInitRef = useRef(true);

  useEffect(() => {
    const keys = restoreSortKeys(id);

    const sorted = [...columns].sort((a, b) => {
      const aIndex = keys.indexOf(tryGetKey(a));
      const bIndex = keys.indexOf(tryGetKey(b));

      return aIndex - bIndex;
    });

    setSortedItems(sorted);
  }, [id, columns]);

  useEffect(() => {
    // 要等初始化完成，跳过第一次
    if (isInitRef.current) {
      isInitRef.current = false;
      return;
    }

    window.localStorage.setItem(
      getSortStoreKey(id),
      JSON.stringify(sortedItems.map((item) => tryGetKey(item))),
    );
  }, [id, sortedItems]);

  return [sortedItems, setSortedItems] as const;
}
