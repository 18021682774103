import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { MenuConfig, useMenus } from './menu';
import {
  generateActiveMenu,
  getBestMatch,
  getMatchedMenuItem,
} from './menu-utils';

export const useSideMenus = (): MenuConfig[] => {
  const [menus] = useMenus();
  const location = useLocation();

  const activeTopMenu = useMemo(() => {
    const matches = getMatchedMenuItem(menus, location.pathname);
    return getBestMatch(matches);
  }, [menus, location]);

  if (!activeTopMenu) {
    return [];
  }

  if (!('children' in activeTopMenu)) {
    return [];
  }

  return activeTopMenu.children ?? [];
};

export const useActiveMenus = (menus: MenuConfig[]) => {
  const location = useLocation();

  const activeMenus = useMemo(
    () =>
      Array.from(generateActiveMenu(menus, location.pathname)).map(
        (menu) => menu.key as string,
      ),
    [menus, location],
  );

  return activeMenus;
};
