export const QUERY_ME = ['me'];

/**
 * 用户信息的 Key
 */
export const PROFILE = [...QUERY_ME, 'PROFILE'];

/**
 * 用户角色的 key
 */
export const USERROLELIST = ['USER_ROLE_LIST'] as const;

export const ALLUSERROLE = [...USERROLELIST, 'ALL'];

/**
 * 校验码
 */
export const CAPTCHA = ['LOGIN_CAPTCHA'] as const;
