import { ImageUploadSaveResponse } from '@/features/file';
import { UploadFile } from 'antd';
import { ImageValue } from './types';

export function toImageValue(
  file: UploadFile<ImageUploadSaveResponse>,
): ImageValue {
  return {
    id: file.response?.id,
    uid: file.uid,
    url: file.url,
    name: file.name,
    percent: file.percent,
    status: file.status,
    thumbUrl: file.thumbUrl,
  };
}

export function toImageValues(fileList: UploadFile<ImageUploadSaveResponse>[]) {
  return fileList.map((item) => toImageValue(item));
}
