// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import { defaultContext, QueryClient, QueryClientProvider } from '/builds/rS5wyfxs/2/lf/erp/frontend/node_modules/@tanstack/react-query';
import { ReactQueryDevtools } from '/builds/rS5wyfxs/2/lf/erp/frontend/node_modules/@tanstack/react-query-devtools';
import { reactQuery as reactQueryConfig } from '@/app';
const client = new QueryClient(reactQueryConfig.queryClient || {});
export function rootContainer(container) {
  return (
    <QueryClientProvider client={client} context={defaultContext}>
      {container}
      
    </QueryClientProvider>
  );
}
