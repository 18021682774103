import type { QueryKey } from '@tanstack/react-query';
import { useQueryClient } from '@umijs/max';
import { Key, SortOrder } from 'antd/es/table/interface';
import { useCallback, useMemo, useState } from 'react';

import { Pagination } from '../types';

export interface AntdSort {
  /**
   * 排序方式
   */
  order: SortOrder;
  /**
   * 字段
   */
  field: Key;
}

interface Sort {
  sort?: string;
}

export function usePagingQuery<
  TQuery extends object,
  TQueryKey extends QueryKey = QueryKey,
>(
  getQueryKey: (queryParams: TQuery & Pagination) => TQueryKey,
  initialQuery?: TQuery,
  defaultPagination?: Pagination,
) {
  const queryClient = useQueryClient();
  const [query, setQuery] = useState<TQuery | undefined>(initialQuery);
  const [pagination, setPagination] = useState<Pagination>({
    pageNumber: defaultPagination?.pageNumber ?? 1,
    pageSize: defaultPagination?.pageSize ?? 100,
  });
  const [sort, setSort] = useState<AntdSort>();

  const sortParams = useMemo<string | undefined>(() => {
    if (!sort) {
      return;
    }

    if (typeof sort.field !== 'string') {
      return;
    }

    return `${sort.order === 'descend' ? '-' : ''}${sort.field}`;
  }, [sort]);

  const queryParams = useMemo<TQuery & Pagination & Sort>(
    () =>
      ({
        ...query,
        sort: sortParams,
        pageNumber: pagination.pageNumber,
        pageSize: pagination?.pageSize ?? 10,
      } as TQuery & Pagination & Sort),
    [query, pagination, sortParams],
  );

  const changePageNumber = useCallback(
    (pageNumber: number, pageSize?: number) => {
      setPagination((value) => {
        const newPagination: Pagination = { ...value, pageNumber };

        if (pageSize != null) {
          newPagination.pageSize = pageSize;
        }

        return newPagination;
      });
    },
    [],
  );

  const changePageSize = useCallback(
    (pageSize: number, pageNumber?: number) => {
      setPagination((value) => {
        const newPagination: Pagination = { ...value, pageNumber: 1, pageSize };

        if (pageNumber != null) {
          newPagination.pageNumber = pageNumber;
        }

        return newPagination;
      });
    },
    [],
  );

  const changeSort = useCallback((sort?: AntdSort) => {
    setSort(sort);
    setPagination((value) => {
      return {
        ...value,
        pageNumber: 1,
      };
    });
  }, []);

  const queryMany = useCallback(
    (query?: TQuery | ((query: TQuery | undefined) => TQuery | undefined)) => {
      const INIT_PAGE_NUMBER = 1;

      setQuery((oldQuery) => {
        const newQuery = typeof query === 'function' ? query(oldQuery) : query;
        const queryKey = getQueryKey({
          ...newQuery,
          ...sort,
          ...pagination,
          pageNumber: INIT_PAGE_NUMBER,
        } as unknown as TQuery & Pagination);

        queryClient.refetchQueries({
          queryKey,
          exact: true,
          type: 'active',
        });

        return newQuery;
      });
      setPagination({ pageNumber: INIT_PAGE_NUMBER });
    },
    [pagination, getQueryKey, queryClient, sort],
  );

  return {
    changePageNumber,
    changePageSize,
    queryMany,
    queryParams,
    sort,
    pagination,
    changeSort,
  };
}
