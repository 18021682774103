import { ReactElement, ReactNode, cloneElement, forwardRef } from 'react';

export interface NumberRangeProps {
  value?: [number?, number?];
  onChange?: (value?: [number?, number?]) => void;
  min: ReactElement;
  max: ReactElement;
  children?: (min: ReactNode, max: ReactNode) => ReactNode;
}

export const NumberRange = forwardRef<HTMLDivElement, NumberRangeProps>(
  (props, ref) => {
    const [minValue, maxValue] = props.value ?? [];

    const minInput = cloneElement(props.min, {
      value: minValue,
      onChange: (value?: number) => {
        props.onChange?.([value, maxValue]);
      },
    });

    const maxInput = cloneElement(props.max, {
      value: maxValue,
      onChange: (value?: number) => {
        props.onChange?.([minValue, value]);
      },
    });

    const content = props.children ? (
      props.children(minInput, maxInput)
    ) : (
      <>
        {minInput}
        <div className="px-4">~</div>
        {maxInput}
      </>
    );

    return (
      <div ref={ref} className="flex items-center">
        {content}
      </div>
    );
  },
);
