import { useScrollContainerGetter } from '@/features/workspace';
import type { RefSelectProps, SelectProps as AntdSelectProps } from 'antd';
import { Select as AntdSelect } from 'antd';
import { BaseOptionType, DefaultOptionType } from 'antd/es/select';
import {
  forwardRef,
  ForwardRefRenderFunction,
  ReactElement,
  Ref,
  useMemo,
} from 'react';

export type { RefSelectProps } from 'antd';

export interface SelectProps<
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
> extends AntdSelectProps<ValueType, OptionType> {
  /**
   * 禁止清除选择
   */
  disallowClear?: boolean;

  /**
   * 隐藏搜索
   */
  hideSearch?: boolean;
}

const InnerSelect: ForwardRefRenderFunction<RefSelectProps, SelectProps> = (
  props,
  ref,
) => {
  const {
    disallowClear,
    allowClear,
    showSearch,
    hideSearch,
    onChange: _onChange,
    ...selectProps
  } = props;
  const getPopupContainer = useScrollContainerGetter();

  const shouldClear = useMemo(() => {
    if (typeof allowClear === 'boolean') {
      return allowClear;
    }
    if (typeof disallowClear === 'boolean') {
      return !disallowClear;
    }
    return true;
  }, [disallowClear, allowClear]);

  const shouldShowSearch = useMemo(() => {
    if (typeof showSearch === 'boolean') {
      return showSearch;
    }
    if (typeof hideSearch === 'boolean') {
      return !hideSearch;
    }
    return true;
  }, [showSearch, hideSearch]);

  const onChange = useMemo<SelectProps['onChange']>(() => {
    if (!_onChange) {
      return;
    }

    return (value, option) => {
      _onChange?.(value ?? null, option);
    };
  }, [_onChange]);

  return (
    <AntdSelect
      ref={ref}
      allowClear={shouldClear}
      showSearch={shouldShowSearch}
      autoClearSearchValue={false}
      placeholder="请选择"
      optionFilterProp="label"
      getPopupContainer={getPopupContainer}
      {...selectProps}
      onChange={onChange}
    />
  );
};

export interface SelectForwardRef {
  <
    ValueType = any,
    OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
  >(
    props: SelectProps<ValueType, OptionType> & {
      ref?: Ref<RefSelectProps>;
    },
  ): ReactElement;
  Option: typeof AntdSelect.Option;
  OptGroup: typeof AntdSelect.OptGroup;
}

export const Select = forwardRef(InnerSelect) as unknown as SelectForwardRef;

Select.Option = AntdSelect.Option;
Select.OptGroup = AntdSelect.OptGroup;
