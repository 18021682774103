import { useEffect, useState } from 'react';
import { getWidthStoreKey, restoreWidthConfigs } from './utils';

export interface ColumnWidthConfigs {
  [key: string]: number;
}

export const useColumnWidth = (id: string) => {
  const [widths, setWidths] = useState<ColumnWidthConfigs>(
    restoreWidthConfigs(id),
  );

  useEffect(() => {
    window.localStorage.setItem(getWidthStoreKey(id), JSON.stringify(widths));
  }, [id, widths]);

  return [widths, setWidths] as const;
};
