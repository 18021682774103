import clsx from 'clsx';
import {
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  PropsWithChildren,
} from 'react';

/**
 * 用于只读的表单字段
 */
export const FormItemText: FC<
  PropsWithChildren<
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  >
> = (props) => {
  return (
    <div className={clsx('ant-form-item-control-input', props.className)}>
      <div className="ant-form-item-control-input-content flex">
        <div className="ant-input ant-input-disabled h-8 overflow-hidden">
          {props.children}
        </div>
      </div>
    </div>
  );
};
