import { BackTop as AntdBackTop, BackTopProps } from 'antd';
import { FC } from 'react';

import { useScrollContainerGetter } from '@/features/workspace';
import clsx from 'clsx';

/**
 * Antd BackTop 默认的滚动父元素是 html，导致滚动元素不正确导致组件无法使用的问题
 *
 * 使用依赖注入的 Scroll Container 修复滚动父元素
 * @param props
 * @returns
 */
export const BackTop: FC<BackTopProps> = (props) => {
  const getContainer = useScrollContainerGetter();
  return (
    <AntdBackTop
      target={getContainer}
      {...props}
      className={clsx(props.className, 'right-8', 'bottom-24')}
      style={{ bottom: 96, zIndex: 1000 }}
    />
  );
};
