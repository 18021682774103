import { useBoolean, useClickAway } from 'ahooks';
import clsx from 'clsx';
import React, { useRef } from 'react';

import { ColorChangeHandler, ColorResult, SketchPicker } from 'react-color';
import styles from './color-picker-control.module.less';

export interface ColorPickerControlProps {
  onChange?: (color: string) => void;
  value?: string; // 默认颜色
  disabled?: boolean;
}

/**
 * 颜色选择 自定义form 组件
 */
export const ColorPickerControl: React.FC<ColorPickerControlProps> = ({
  value,
  onChange,
  disabled,
}) => {
  const ref = useRef<HTMLDivElement | any>();
  const ref1 = useRef<HTMLDivElement | any>();

  const [state, { setTrue, setFalse }] = useBoolean(false); // 控制颜色色板的打开关闭

  // 点击空白处 关闭颜色面板
  useClickAway(() => {
    setFalse();
  }, [ref, ref1]);

  // 颜色变化onChange
  const onColorChange: ColorChangeHandler = (color: ColorResult) => {
    const rgb = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`;
    onChange?.(rgb);
  };

  return (
    <div
      className={clsx(styles['color-picker-box'], {
        [styles['color-picker-disable']]: disabled,
      })}
    >
      <div className={styles['color-value']}>{value}</div>
      <div className={styles['color-picker-content']}>
        <div
          ref={ref}
          style={{ backgroundColor: value }}
          className={styles['select-color-box']}
          onClick={() => (!state && !disabled ? setTrue() : setFalse())}
        />
        {state && (
          <div ref={ref1} className={styles['sket-picker-wrapper']}>
            <SketchPicker onChange={onColorChange} color={value} />
          </div>
        )}
      </div>
    </div>
  );
};
