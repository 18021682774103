import axios from 'axios';
import { ImageUploadSaveResponse } from './file.dto';

export class FileService {
  /**
   * 上传文件
   */
  async upload(url: string) {
    const { data } = await axios.post<ImageUploadSaveResponse>(
      'api/files/upload',
      { url },
    );

    return data;
  }
}

export const fileService = new FileService();
