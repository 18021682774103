import { Space, Tag } from 'antd';
import clsx from 'clsx';
import { ReactNode } from 'react';

import S from './button-toggle.module.less';

const { CheckableTag } = Tag;

export interface ButtonToggleGroupProps<TValue = number> {
  value?: TValue | null;

  items?: ButtonToggleProps<TValue>[];

  onChange?: (value: TValue) => void;
}

export function ButtonToggleGroup<TValue extends string | number = number>(
  props: ButtonToggleGroupProps<TValue>,
) {
  return props.items?.length ? (
    <Space size={[12, 12]} wrap>
      {props.items.map((item, i) => (
        <ButtonToggle
          key={item.value.toString() + i}
          {...item}
          checked={props.value === item.value}
          onChange={(value, checked) => {
            props.onChange?.(value);
            item.onChange?.(value, checked);
          }}
        />
      ))}
    </Space>
  ) : null;
}

export interface ButtonToggleProps<TValue = number> {
  value: TValue;

  checked?: boolean;

  disabled?: boolean;

  onChange?: (value: TValue, checked: boolean) => void;

  children?: ReactNode;
}

export function ButtonToggle<TValue = number>(
  props: ButtonToggleProps<TValue>,
) {
  return (
    <CheckableTag
      className={clsx(S.override, {
        disabled: props.disabled ?? false,
      })}
      checked={props.checked ?? false}
      onChange={(checked) => props.onChange?.(props.value, checked)}
    >
      {props.children}
    </CheckableTag>
  );
}
