import { Spin } from 'antd';
import type { Property } from 'csstype';
import { FC } from 'react';

interface LoadingProps {
  position: Property.Position;
}

const Loading: FC<LoadingProps> = ({ position = 'fixed' }) => {
  return (
    <div
      style={{
        position,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 2,
      }}
    >
      <Spin size="large"></Spin>
    </div>
  );
};

export default Loading;
