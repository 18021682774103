import type { InputProps, InputRef } from 'antd';
import { Input as AntdInput } from 'antd';
import { TextAreaProps, TextAreaRef } from 'antd/es/input/TextArea';
import { forwardRef } from 'react';

export type { InputProps, InputRef } from 'antd';

export const TextArea = forwardRef<TextAreaRef, TextAreaProps>((props, ref) => {
  let maxLength: TextAreaProps['maxLength'] = 1000;

  if (Object.hasOwn(props, 'maxLength')) {
    maxLength = props.maxLength;
  }

  return (
    <AntdInput.TextArea
      ref={ref}
      maxLength={maxLength}
      placeholder="请输入"
      {...props}
    />
  );
});

export const Input = forwardRef<InputRef, InputProps>((props, ref) => (
  <AntdInput ref={ref} maxLength={250} placeholder="请输入" {...props} />
)) as React.ForwardRefExoticComponent<
  InputProps & React.RefAttributes<InputRef>
> & {
  Group: typeof AntdInput.Group;
  Search: typeof AntdInput.Search;
  TextArea: typeof TextArea;
  Password: typeof AntdInput.Password;
};

Input.Group = AntdInput.Group;
Input.Search = AntdInput.Search;
Input.TextArea = TextArea;
Input.Password = AntdInput.Password;
