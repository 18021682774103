import { createContext, PropsWithChildren, useContext } from 'react';
import {
  FieldArrayPath,
  FieldValues,
  UseFieldArrayReturn,
} from 'react-hook-form';

export const FieldArrayContext = createContext<UseFieldArrayReturn | null>(
  null,
);

export type FieldArrayProviderProps<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
  TKeyName extends string = 'id',
> = UseFieldArrayReturn<TFieldValues, TFieldArrayName, TKeyName>;

export const FieldArrayProvider = <
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
  TKeyName extends string = 'id',
>(
  props: PropsWithChildren<
    FieldArrayProviderProps<TFieldValues, TFieldArrayName, TKeyName>
  >,
) => {
  const { children, ...fieldArrayProps } = props;

  return (
    <FieldArrayContext.Provider
      value={fieldArrayProps as unknown as UseFieldArrayReturn}
    >
      {children}
    </FieldArrayContext.Provider>
  );
};

export function useFieldArrayContext<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
  TKeyName extends string = 'id',
>(): UseFieldArrayReturn<TFieldValues, TFieldArrayName, TKeyName> {
  return useContext(FieldArrayContext) as unknown as UseFieldArrayReturn<
    TFieldValues,
    TFieldArrayName,
    TKeyName
  >;
}
