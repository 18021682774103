import { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Checkbox, List } from 'antd';
import { Key } from 'react';

import { HolderOutlined } from '@ant-design/icons';
import { ColumnItemConfig } from './column.types';
import { tryGetKey } from './utils';

import S from './sortable-item.module.less';

export interface SortableGroupItemProps<T> {
  id: UniqueIdentifier;
  items: Array<ColumnItemConfig<T>>;
  hiddenKeys: Key[];
  onCheck?: (key: Key, checked: boolean) => void;
}

export function SortableGroupItem<T>(props: SortableGroupItemProps<T>) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <List.Item
      ref={setNodeRef}
      className={S.override}
      style={style}
      {...attributes}
    >
      <div className="flex items-start">
        <Button
          ref={setActivatorNodeRef}
          {...listeners}
          type="text"
          icon={<HolderOutlined />}
          size="small"
          shape="circle"
        />
        <div>
          {props.items.map((item) => (
            <div key={tryGetKey(item)}>
              <Checkbox
                checked={!props.hiddenKeys.includes(tryGetKey(item))}
                onChange={(e) => {
                  props.onCheck?.(tryGetKey(item), e.target.checked);
                }}
              >
                {typeof item.title === 'function' ? item.title({}) : item.title}
              </Checkbox>
            </div>
          ))}
        </div>
      </div>
    </List.Item>
  );
}

export interface SortableItemProps<T> {
  id: UniqueIdentifier;
  column: ColumnItemConfig<T>;
  hiddenKeys: Key[];
  onCheck?: (key: Key, checked: boolean) => void;
}

export function SortableItem<T>(props: SortableItemProps<T>) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { title } = props.column;

  return (
    <List.Item
      ref={setNodeRef}
      className={S.override}
      style={style}
      {...attributes}
    >
      <div className="flex">
        <Button
          ref={setActivatorNodeRef}
          {...listeners}
          type="text"
          icon={<HolderOutlined />}
          size="small"
          shape="circle"
        />
        <Checkbox
          checked={!props.hiddenKeys.includes(tryGetKey(props.column))}
          onChange={(e) => {
            props.onCheck?.(tryGetKey(props.column), e.target.checked);
          }}
        >
          {typeof title === 'function' ? title({}) : title}
        </Checkbox>
      </div>
    </List.Item>
  );
}
