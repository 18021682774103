import clsx from 'clsx';
import { ComponentPropsWithoutRef, forwardRef } from 'react';

import S from './paper.module.less';

/**
 * 一张白纸
 *
 * 白底组件块的基础元素
 */
export const Paper = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<'div'>
>((props, ref) => {
  return (
    <div ref={ref} {...props} className={clsx(S.paper, props.className)}>
      {props.children}
    </div>
  );
});
