import { SettingOutlined } from '@ant-design/icons';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Button, Drawer, List } from 'antd';
import { Key, useEffect, useMemo, useState } from 'react';

import { ColumnItemConfig, ColumnsConfig } from './column.types';
import { useHiddenKeys } from './hidden-keys.hook';
import { SortableGroupItem, SortableItem } from './sortable-item';
import { useSortedColumns } from './sorted-columns.hook';
import { tryGetKey } from './utils';

import S from './column-custom.module.less';

export interface ColumnCustomProps<T> {
  id: string;
  columns: ColumnsConfig<T>;
  onChange?: (columns: ColumnsConfig<T>) => void;
  onReset?: () => void;
}

export function ColumnCustom<T>(props: ColumnCustomProps<T>) {
  const { onChange } = props;
  const [open, setOpen] = useState(false);
  const sensorts = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const [hiddenKeys, setHiddenKeys] = useHiddenKeys(props.id);
  const [sortedItems, setSortedItems] = useSortedColumns(
    props.id,
    props.columns,
  );

  useEffect(() => {
    const visibleItems = sortedItems
      .filter((item) => {
        if ('items' in item) {
          return true;
        }

        return !hiddenKeys.includes(tryGetKey(item));
      })
      .map((item) => {
        if ('items' in item) {
          const filtered = item.items.filter(
            (subItem) => !hiddenKeys.includes(tryGetKey(subItem)),
          );

          return {
            ...item,
            items: filtered,
          };
        }

        return item;
      })
      .filter((item) => ('items' in item ? item.items.length > 0 : item));

    onChange?.(visibleItems);
  }, [onChange, sortedItems, hiddenKeys]);

  const groups = useMemo(() => {
    return sortedItems.map((column) => {
      if ('items' in column) {
        return {
          id: column.key as string,
          items: column.items,
        } as { id: string; items: Array<ColumnItemConfig<T>> };
      }

      return {
        id: tryGetKey(column) as string,
        item: column,
      } as { id: string; item: ColumnItemConfig<T> };
    });
  }, [sortedItems]);

  const onDragEnd = (e: DragEndEvent) => {
    const { active, over } = e;

    if (!over?.id) {
      return;
    }

    if (active.id !== over.id) {
      setSortedItems((items) => {
        const oldIndex = items.findIndex(
          (item) => tryGetKey(item) === active.id,
        );
        const newIndex = items.findIndex((item) => tryGetKey(item) === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const onCheck = (key: Key, checked: boolean) => {
    setHiddenKeys((keys) => {
      const set = new Set(keys);

      if (checked) {
        set.delete(key);
      } else {
        set.add(key);
      }

      return [...set];
    });
  };

  const onReset = () => {
    props.onReset?.();

    setHiddenKeys([]);

    setSortedItems((items) => {
      return [...items].sort((a, b) => {
        const aIndex = props.columns.findIndex((col) => col.key === a.key);
        const bIndex = props.columns.findIndex((col) => col.key === b.key);

        return aIndex - bIndex;
      });
    });
  };

  return (
    <>
      <Button
        type="default"
        icon={<SettingOutlined />}
        onClick={() => setOpen(true)}
      >
        自定义列
      </Button>
      <Drawer
        className={S.override}
        open={open}
        title="自定义列"
        destroyOnClose
        width={240}
        onClose={() => {
          setOpen(false);
        }}
        footer={<Button onClick={onReset}>重置</Button>}
      >
        <DndContext
          sensors={sensorts}
          collisionDetection={closestCenter}
          onDragEnd={onDragEnd}
        >
          <SortableContext
            items={groups}
            strategy={verticalListSortingStrategy}
          >
            <List
              size="small"
              dataSource={groups}
              renderItem={(item) => {
                return 'items' in item ? (
                  <SortableGroupItem
                    id={item.id}
                    items={item.items}
                    hiddenKeys={hiddenKeys}
                    onCheck={onCheck}
                  />
                ) : (
                  <SortableItem
                    id={item.id}
                    column={item.item}
                    hiddenKeys={hiddenKeys}
                    onCheck={onCheck}
                  />
                );
              }}
            />
          </SortableContext>
        </DndContext>
      </Drawer>
    </>
  );
}
