import { useMemo } from 'react';

import { Select, SelectProps } from '@/antd';
import { useMyPermissions } from '@/features/user';

export const ExtraPermissionsSelect = ({ ...otherProps }: SelectProps) => {
  const { grade_role_permission } = useMyPermissions();

  const options = useMemo(() => {
    return grade_role_permission?.map((item) => ({
      value: item.permissions.name,
      label: item.permissions.cname,
    }));
  }, [grade_role_permission]);

  return <Select options={options} {...otherProps}></Select>;
};
