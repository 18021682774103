import clsx from 'clsx';
import { ReactNode } from 'react';
import { ColumnCustom } from './column-custom';
import { ColumnsConfig } from './column.types';

export interface ToolbarProps<T> {
  id: string;
  columns?: ColumnsConfig<T>;
  toolbar?: ReactNode;
  toolbarClassName?: string;
  primaryActions?: ReactNode;
  onChangeColumns?: (columns: ColumnsConfig<T>) => void;
  onResetColumns?: () => void;
}

/**
 * 表格顶部工具栏
 *
 * `primaryActions` 用于放置主操作按钮
 *
 * `toolbar` 用来放置其他内容
 */
export function Toolbar<T>(props: ToolbarProps<T>) {
  return (
    <div className="flex mb-4 w-full overflow-hidden justify-between">
      <div
        className={clsx('flex-auto flex justify-end', props.toolbarClassName)}
      >
        {props.toolbar}
      </div>
      {props.columns && (
        <div className="ml-2 flex-none">
          <ColumnCustom
            id={props.id}
            columns={props.columns}
            onChange={props.onChangeColumns}
            onReset={props.onResetColumns}
          />
        </div>
      )}
      {props.primaryActions && (
        <div className="ml-2 flex-none">{props.primaryActions}</div>
      )}
    </div>
  );
}
