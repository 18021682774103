import {
  ImageUploadSaveResponse,
  ModuleType,
  useSaveImageByURL,
} from '@/features/file';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Input, InputRef, UploadProps } from 'antd';
import { isAxiosError } from 'axios';
import { forwardRef, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { InferType, object, string } from 'yup';
import { FormControl } from '../form';
import { ImageValue } from './types';
import { UploaderControl } from './uploader-control';

export interface InputUploaderProps
  extends Pick<UploadProps<ImageUploadSaveResponse>, 'children'> {
  value?: ImageValue;
  disabled?: boolean;
  onChange?: (value?: ImageValue) => void;
  moduleType: ModuleType;
}

const schema = object({
  url: string()
    .label('图片链接')
    .url('请输入有效的 URL')
    .test(
      'unknownFormat',
      '图片地址不合法，地址需要以jpg、jpeg、git、png、webp结尾',
      (url) => {
        if (!url) {
          return true;
        }

        return /\.(jpg|jpeg|gif|png|webp)$/.test(url);
      },
    ),
});

type FormValues = InferType<typeof schema>;

export const InputUploaderControl = forwardRef<InputRef, InputUploaderProps>(
  (props, ref) => {
    const { value, onChange, disabled, moduleType, ...restUploadProps } = props;

    const { isLoading, mutateAsync, error } = useSaveImageByURL();

    const { control, handleSubmit, setError, setValue, watch, formState } =
      useForm<FormValues>({
        mode: 'all',
        resolver: yupResolver(schema),
      });

    const inputValue = watch('url');

    const images = useMemo<ImageValue[]>(() => {
      if (!value) {
        return [];
      }

      return [value];
    }, [value]);

    useEffect(() => {
      setValue('url', value?.url);
    }, [value, setValue]);

    useEffect(() => {
      if (isAxiosError(error)) {
        setError('url', {
          message: error.response?.data.message,
        });
      }
    }, [error, setError]);

    const onSubmit = handleSubmit(async ({ url }) => {
      if (!url) {
        return;
      }

      const result = await mutateAsync(url);
      props.onChange?.({
        id: result.id,
        uid: result.id + '',
        name: result.title,
        status: 'done',
        url: result.path,
        thumbUrl: result.path,
        percent: 100,
      });
    });

    return (
      <div>
        <FormControl
          control={control}
          name="url"
          wrapperCol={{ span: 24 }}
          render={({ field }) => {
            return (
              <Input.Group compact>
                <Input
                  style={{ width: 'calc(100% - 64px)' }}
                  {...field}
                  ref={(el) => {
                    if (el == null) {
                      return;
                    }

                    if (typeof ref === 'function') {
                      ref(el);
                    } else if (ref) {
                      ref.current = el;
                    }

                    field.ref(el);
                  }}
                  disabled={props.disabled}
                  placeholder="请输入图片链接"
                />
                <Button
                  className="w-16"
                  type="primary"
                  loading={isLoading}
                  disabled={props.disabled || !inputValue || !formState.isValid}
                  onClick={onSubmit}
                >
                  上传
                </Button>
              </Input.Group>
            );
          }}
        />
        <UploaderControl
          {...restUploadProps}
          moduleType={moduleType}
          disabled={disabled}
          maxCount={1}
          value={images}
          listType="picture-card"
          onChange={(value) => {
            const image = value.at(0);
            setValue('url', image?.url);
            props.onChange?.(image);
          }}
        >
          {props.value ? null : '上传图片'}
        </UploaderControl>
      </div>
    );
  },
);
