import type { TableProps } from 'antd';
import { Table as AntdTable } from 'antd';
import { forwardRef } from 'react';

export type {
  TableColumnGroupType,
  TableColumnProps,
  TableColumnsType,
  TableColumnType,
  TablePaginationConfig,
  TableProps,
} from 'antd';

export const FowardedTable = forwardRef<HTMLDivElement, TableProps<any>>(
  (props, ref) => <AntdTable ref={ref} size="small" {...props} />,
);
