import clsx from 'clsx';
import { FC, MouseEventHandler } from 'react';

export interface ExpandIconProps {
  expanded: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

/**
 * Antd Table 中使用的展开功能的图标
 *
 * 这里为了能够自主控制展开图标的状态，拷贝了一份它的实现
 */
export const ExpandIcon: FC<ExpandIconProps> = (props) => {
  return (
    <button
      type="button"
      className={clsx('ant-table-row-expand-icon', {
        'ant-table-row-expand-icon-expanded': props.expanded,
        'ant-table-row-expand-icon-collapsed': !props.expanded,
      })}
      aria-label={props.expanded ? '关闭行' : '展开行'}
      onClick={(e) => props.onClick?.(e)}
    ></button>
  );
};
