import { Radio, RadioGroupProps as AntdRadioGroupProps } from 'antd';
import clsx from 'clsx';
import { forwardRef } from 'react';
import S from './radio.module.less';

export { Radio } from 'antd';
export type { RadioChangeEvent, RadioProps } from 'antd';

export interface RadioGroupProps extends AntdRadioGroupProps {
  /**
   * 是否使用胶囊样式
   *
   * 胶囊样式的会在按钮间留出间距
   *
   * 只有按钮样式有效
   */
  pill?: boolean;
}

export const RadioGroup = forwardRef<HTMLDivElement, RadioGroupProps>(
  (props, ref) => {
    const { pill, ...restProps } = props;

    return (
      <Radio.Group
        ref={ref}
        {...restProps}
        className={clsx(props.className, {
          [S.pill]: props.pill,
        })}
      />
    );
  },
);
