import { useMemo } from 'react';

import { useMyPermissions, useUserProfile } from '@/features/user';

export const useAuthority = (permission: string | string[]) => {
  const { permissions } = useMyPermissions();

  const isGranted = useMemo(() => {
    const requestingPermissions = Array.isArray(permission)
      ? permission
      : [permission];

    return permissions?.some((item) =>
      requestingPermissions.includes(item.name),
    );
  }, [permission, permissions]);

  return isGranted;
};

/**
 * 将给定的 Key 转换成 scoped Key
 *
 * 例如，给定 `key` 为 `spu`，用户 ID 为 `1`，则获得 scoped key `1_spu`
 *
 * 用于需要按用户保存特定信息的场景，例如，按用户保存个性化的表格配置
 *
 * @param keys 需要 Scope 的 Key
 * @returns
 */
export const useUserScopedKey = (...keys: Array<string | undefined | null>) => {
  const key = keys.filter(Boolean).join('_');

  const data = useUserProfile();

  if (!data) {
    console.warn(`暂未获取到用户信息，使用默认的 Key(${key})`);
    return key;
  }

  return `${data.id}_${key}`;
};
