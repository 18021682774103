import axios, { AxiosRequestConfig } from 'axios';

import type { FileSaveBody } from '@/features/file';
import { getFileSign, saveFiles, uploadFiles } from '@/services/files';
import { SignatureData } from '@/utils/upload/types';

class AliyunUploadAdapter {
  async fetchPolicy(
    file: Blob,
    options: { resource: string; mimeType: string },
  ): Promise<SignatureData> {
    const formData = new FormData();
    formData.append('resource', options.resource);
    formData.append('mime_type', options.mimeType);
    const res = await getFileSign(formData);
    return res;
  }

  async put(
    file: any,
    options?: AxiosRequestConfig & {
      saveParams?: Record<keyof FileSaveBody, any>;
    },
  ) {
    // 这个options后面需要更多的定义进来，目前只处理响应相关的，后面可能还需要一些别的额外数据
    const mimeType = file.type;
    const resource = mimeType.split('/')[0];
    const signData = await this.fetchPolicy(file, {
      mimeType,
      resource,
    });

    const uploadFormData = new FormData();
    uploadFormData.append('name', file.name);
    uploadFormData.append('key', signData.path);
    uploadFormData.append('policy', signData.policy);
    uploadFormData.append('OSSAccessKeyId', signData.access_id);
    uploadFormData.append('success_action_status', '200');
    uploadFormData.append('signature', signData.signature);
    uploadFormData.append('file', file);

    await uploadFiles(
      signData.host.replace(/^(https?):/, window.location.protocol),
      uploadFormData,
      {
        headers: {
          'x-oss-object-acl': 'public-read',
        },
        ...options,
      },
    );

    const saveFormData = new FormData();

    saveFormData.append('file_content_type', resource);
    saveFormData.append('key', signData.path);
    saveFormData.append('mime_type', mimeType);
    saveFormData.append('url', `${signData.host}/${signData.path}`);
    saveFormData.append('file_name', file.name);

    if (options) {
      const { saveParams } = options;

      if (saveParams) {
        Object.keys(saveParams).forEach((key) => {
          saveFormData.append(key, saveParams[key as keyof FileSaveBody]);
        });
      }
    }

    const saveResponse = await saveFiles(saveFormData, options);
    return saveResponse;
  }

  async get(name: string, options?: AxiosRequestConfig) {
    const { data } = await axios.get(
      `${window.location.protocol}//erp-test-apps.oss-cn-shenzhen.aliyuncs.com/image/` +
        name,
      options,
    );

    return data;
  }
}

export default AliyunUploadAdapter;
