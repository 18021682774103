import {
  Col,
  ColProps,
  Form as AntdForm,
  FormItemProps as AntdFormItemProps,
  FormProps as AntdFormProps,
  Row,
} from 'antd';
import {
  Children,
  cloneElement,
  createContext,
  FC,
  PropsWithChildren,
  ReactNode,
  useContext,
  useMemo,
} from 'react';
import {
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
} from 'react-hook-form';

import { FormItemText } from '../form-item-text';
import { FormControl } from './form-control';

/**
 * 可用的栏目数
 */
type ValidColumns = 1 | 2 | 3 | 4 | 6 | 12;

/**
 * 总的栅格数
 *
 * 24 格
 */
const TOTAL_SPAN = 24;

/**
 * 默认的栏目
 *
 * 默认就 1 栏
 */
const DEFAULT_COLUMNS: ValidColumns = 1;

/**
 * 默认的栏目间距
 *
 * 默认 16px
 */
const DEFAULT_GUTTER = 16;

export interface FormGridContextValues {
  grid: boolean | 'auto';
  span: number;
  controlSpan: number;
}

const FormGridContext = createContext<FormGridContextValues>({
  grid: false,
  span: 24,
  controlSpan: 24,
});

export interface FormItemContextValues {
  grid: boolean;
  colspan: number;
  controlSpan: number;
}

const FormItemContext = createContext<FormItemContextValues>({
  grid: false,
  colspan: 1,
  controlSpan: 24,
});

export interface FormGridItemProps {
  flex?: ColProps['flex'];
  /**
   * 栅格占位格数
   */
  span?: number;
  /**
   * 栅格左侧的间隔格数，间隔内不可以有栅格
   */
  offset?: number;
  /**
   * 占据的栏位数，如果设置了 `span`，则该属性无效
   *
   * 会自动考虑 controlColspan 的宽度，跨越多栏也会保证尾部整齐
   *
   * 默认 1 栏
   */
  colspan?: number;
  /**
   * 偏移的栏位数，如果设置了 `offset`，则该属性无效
   *
   * 默认不偏移
   */
  coloffset?: number;
  /**
   * 是否为吊尾元素
   *
   * 吊尾元素会自动占据剩余的空间，若控件不足则会另起一行
   */
  tailing?: boolean;
}

/**
 * 表单栅格布局的布局单元，会自动服从 `Form` 中的 `columns` 参数分配的栅格大小
 */
export const FormGridItem: FC<PropsWithChildren<FormGridItemProps>> = (
  props,
) => {
  const colspan = props.colspan ?? 1;
  const coloffset = props.coloffset ?? 0;
  const { span, controlSpan } = useContext(FormGridContext);

  if (props.tailing) {
    return (
      <FormItemContext.Provider value={{ colspan, controlSpan, grid: true }}>
        <div className="flex items-end justify-end flex-auto px-2">
          <AntdForm.Item>{props.children}</AntdForm.Item>
        </div>
      </FormItemContext.Provider>
    );
  }

  const colProps: ColProps = {
    offset: props.offset ?? coloffset * span,
  };

  if (props.flex) {
    colProps.flex = props.flex;
  } else {
    colProps.span = props.span ?? colspan * span;
  }

  return (
    <FormItemContext.Provider value={{ colspan, controlSpan, grid: true }}>
      <Col {...colProps}>{props.children}</Col>
    </FormItemContext.Provider>
  );
};

export interface FormGridProps<TValues = any>
  extends Omit<AntdFormProps<TValues>, 'children'> {
  /**
   * 开启后将支持网格化布局
   *
   * 默认需要使用 `FormGridItem` 对表单控件进行网格化布局
   *
   * 使用 `auto` 已开启自动化网格布局
   */
  grid?: boolean | 'auto';
  /**
   * 表单的栏数
   *
   * 默认为 1 栏
   */
  columns?: ValidColumns;
  /**
   * 栏间距
   *
   * 默认为 16px
   */
  gutter?: number;
  /**
   * 表单控件宽度，用分数表示
   *
   * 默认为 1
   */
  controlColspan?: '1/2' | '3/4' | '1';
  /**
   * 表单吊尾元素
   *
   * 可以放置按钮之类的一些东西
   */
  tail?: ReactNode;
  /**
   * 这个 children 不再支持 antd form 的 render props 用法
   */
  children?: any;
}

/**
 * 表单网格组件
 *
 * 基于 Antd `Form` 组件的扩展，增加了自动化网格布局功能
 *
 * 根据 UI 设计稿规范，表单控件采用垂直布局的方式
 *
 * 自动布局
 *
 * 设置 `grid` 属性为 `true` 开启自动布局功能
 *
 * @example
 *
 * ```tsx
 * <Form grid="auto" columns={2} controlColspan="3/4">
 *   <Form.Item control={control} name="orderNo" label="订单号" required>
 *     <Input />
 *   </Form.Item>
 *   <Form.Item control={control} name="tradeNo" label="交易号" required>
 *     <Input />
 *   </Form.Item>
 *   <Form.Item control={control} name="shopId" label="店铺" required>
 *     <ShopSelect />
 *   </Form.Item>
 *   <Form.Item control={control} name="money" label="商品总售价">
 *     <MoneyInputNumber />
 *   </Form.Item>
 *   <Form.Item control={control} name="shippingIncome" label="运费收入">
 *     <MoneyInputNumber />
 *   </Form.Item>
 *   <Form.Item control={control} name="insurance" label="保险收入">
 *     <MoneyInputNumber />
 *   </Form.Item>
 * </Form>
 * ```
 *
 * 手动布局
 *
 * 配合 `Form.GridItem` 组件完成手动布局
 *
 * 可以使用 `colspan` 参数以使表单组件能够跨越多栏及使用 `coloffset` 参数对表单组件进行偏移
 *
 * 对于列表页面中的查询表单，通常最后都有一个操作栏，可以使用 `FormGrid.Item` 的 `tailing` 参数来让其自动浮动到右侧
 *
 * 对于自动布局的情况下，可以通过使用 `Form` 的 `tail` 属性来设置操作栏
 *
 * 更多配置相见 @see {@link FormGridItemProps}
 *
 * @example
 *
 * ```tsx
 * <Form grid columns={6}>
 *   <Form.GridItem>
 *     <Form.item label="店铺">
 *       <Select mode="multiple" allowClear placeholder="不限" />
 *     </Form.item>
 *   </Form.GridItem>
 *   <Form.GridItem>
 *     <Form.item label="自定义分类">
 *       <Select allowClear placeholder="不限" />
 *     </Form.item>
 *   </Form.GridItem>
 *   <Form.GridItem colspan={2}>
 *     <Form.Item label="订单信息">
 *       <Input.Group compact>
 *         <Form.item noStyle>
 *           <Select />
 *         </Form.item>
 *         <Form.item noStyle>
 *           <Select />
 *         </Form.item>
 *         <Form.item noStyle>
 *           <Input />
 *         </Form.item>
 *       </Input.Group>
 *     </Form.Item>
 *   </Form.GridItem>
 *   <Form.GridItem tailing>
 *     <Space>
 *       <Button>重置</Button>
 *       <Button type="primary">查询</Button>
 *     </Space>
 *   </Form.GridItem>
 * </Form>
 * ```
 */
export function Form<TValues = any>(props: FormGridProps<TValues>) {
  let {
    grid,
    columns,
    gutter,
    controlColspan,
    tail,
    children,
    wrapperCol,
    ...restProps
  } = props;

  columns ??= DEFAULT_COLUMNS;
  const span = TOTAL_SPAN / columns;
  gutter ??= DEFAULT_GUTTER;

  let controlSpan = TOTAL_SPAN;

  switch (controlColspan ?? '1') {
    case '1/2':
      controlSpan = TOTAL_SPAN * 0.5;
      break;
    case '3/4':
      controlSpan = TOTAL_SPAN * 0.75;
      break;
    case '1':
      controlSpan = TOTAL_SPAN;
      break;
    default:
      console.warn('controlColspan 只能取 1, 1/2 或 3/4 其中之一');
  }

  if (grid) {
    wrapperCol ??= {};
    wrapperCol.span = controlSpan;
  }

  /**
   * 只有有效的 `grid` 参数才会开启栅格化表单
   */
  children = useMemo(() => {
    if (!grid) {
      return props.children;
    }

    if (grid === true) {
      return (
        <FormGridContext.Provider value={{ grid, span, controlSpan }}>
          <Row gutter={gutter}>{props.children}</Row>
        </FormGridContext.Provider>
      );
    }

    if (grid === 'auto') {
      return (
        <FormGridContext.Provider value={{ grid, span, controlSpan }}>
          <Row gutter={gutter}>
            {Children.map(props.children, (child) => (
              <FormGridItem>{child}</FormGridItem>
            ))}
            {tail && <FormGridItem tailing>{tail}</FormGridItem>}
          </Row>
        </FormGridContext.Provider>
      );
    }
  }, [props.children, grid, span, controlSpan, tail, gutter]);

  return (
    <AntdForm layout="vertical" wrapperCol={wrapperCol} {...restProps}>
      {children}
    </AntdForm>
  );
}

type CustomControllerProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<
  ControllerProps<TFieldValues, TName>,
  'render' | 'rules' | 'shouldUnregister' | 'defaultValue' | 'name'
>;

type CustomAntdFormItemProps = Omit<AntdFormItemProps, 'name'>;

export interface FormItemProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends CustomAntdFormItemProps,
    CustomControllerProps<TFieldValues, TName> {
  name?: TName;
}

/**
 * 扩展的 antd `FormItem`，增加了对栅格布局的感知能力
 *
 * 能够自动计算 FormItem 的大小，计算的时候会自动考虑 controlSpan 的大小
 *
 * 默认的 antd `Form.Item` 需要手动设置栅格大小，如果想要使用 Form 的栅格能力需要使用改组件替代
 */
export function FormItem<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: FormItemProps<TFieldValues, TName>) {
  const { name, control, ...restProps } = props;
  const { colspan, controlSpan, grid } = useContext(FormItemContext);

  let formItem = <AntdForm.Item name={name} {...restProps} />;

  if (grid) {
    formItem = (
      <AntdForm.Item
        name={name}
        wrapperCol={{
          ...props.wrapperCol,
          span:
            props.wrapperCol?.span ??
            (TOTAL_SPAN / colspan) * (colspan - 1) + controlSpan / colspan,
        }}
        {...restProps}
      />
    );
  }

  if (!name || !control) {
    return formItem;
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ fieldState, formState }) => {
        const { isTouched, isDirty, invalid } = fieldState;
        const { isSubmitted } = formState;

        const shouldShowError =
          (isTouched || isDirty || isSubmitted) && invalid;

        const getValidateStatus = (): FormItemProps['validateStatus'] => {
          if (shouldShowError) {
            return 'error';
          }
        };

        return cloneElement(formItem, {
          validateStatus: getValidateStatus() ?? props.validateStatus,
        });
      }}
    />
  );
}

Form.GridItem = FormGridItem;
Form.Item = FormItem;
Form.Control = FormControl;
Form.ItemText = FormItemText;

Form.ErrorList = AntdForm.ErrorList;
Form.List = AntdForm.List;
Form.Provider = AntdForm.Provider;
Form.useForm = AntdForm.useForm;
Form.useFormInstance = AntdForm.useFormInstance;
Form.useWatch = AntdForm.useWatch;
