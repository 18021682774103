import { createFromIconfontCN } from '@ant-design/icons';
import { IconFontProps } from '@ant-design/icons/lib/components/IconFont';
import { FC } from 'react';

export const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_3964178_w88t401cfk.js',
});

export const MenuIcon: FC<IconFontProps> = (props) => (
  <IconFont {...props} style={{ fontSize: 20 }} />
);
