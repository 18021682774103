import { BooleanNumber } from '@/common/types';
import { BizError } from '@/core';
import { useMutation, useQuery, useQueryClient } from '@umijs/max';
import { useCallback, useEffect } from 'react';
import { useUserProfile } from './user-context';
import {
  PasswordCredentialDto,
  ResetPasswordDto,
  SendOneTimePasswordDto,
  UserRole,
  VerifyOneTimePasswordDto,
} from './user.dto';
import { ChangePasswordEntity } from './user.entity';
import * as UserKeys from './user.keys';
import { userService } from './user.service';

/**
 * 用户登录 Hook
 * @returns
 */
export const useLogin = () => {
  return useMutation({
    mutationFn: async (data: PasswordCredentialDto) => {
      return userService.login(data);
    },
  });
};

/**
 * 当用户登出后需要清理用户信息缓存
 */
export const useAutoClearProfile = () => {
  const queryClient = useQueryClient();

  useEffect(() => {
    return () => {
      queryClient.removeQueries({
        queryKey: UserKeys.PROFILE,
        exact: true,
      });
    };
  }, [queryClient]);
};

/**
 * 修改密码 Hook
 */
export const useChangePassword = () => {
  return useMutation({
    mutationFn: async (data: ChangePasswordEntity): Promise<void> => {
      await userService.changePassword(data);
    },
  });
};

/**
 * 用户登出 Hook
 */
export const useLogout = () => {
  return useCallback(() => {
    userService
      .logout()
      // WHY: 只是通知服务端用户已退出，不管成功与否均可
      .catch((error) => {
        if (error instanceof BizError) {
          error.markAsHandled();
        }
      })
      .finally(() => {
        userService.clearToken();
      });
  }, []);
};

/**
 * 获取我的信息
 *
 * @returns 我的信息
 */
export const useMyProfile = () => {
  return useQuery({
    queryKey: UserKeys.PROFILE,
    queryFn: async () => {
      const data = await userService.getUserProfile();

      return data;
    },
    /**
     * 不需要缓存，每次都要重新获取最新的
     */
    cacheTime: 0,
    /**
     * 使用 Suspense 来阻塞子组件的渲染，确保子组件一定能够获取到用户信息
     */
    suspense: true,
  });
};

/**
 * 获取我的所属公司列表
 *
 * @returns 公司列表
 */
export const useMyCompanies = () => {
  const data = useUserProfile(true);

  return data?.company;
};

/**
 * 获取我的当前主职公司
 *
 * @returns 公司信息
 */
export const useMyCompany = () => {
  const data = useMyCompanies();

  return data?.find((item) => item.is_primary_position === BooleanNumber.True);
};

/**
 * 获取我的当前权限列表
 *
 * @returns 权限列表
 */
export const useMyPermissions = () => {
  const data = useUserProfile();
  const extraPermissionMap = new Map<string, string>();
  for (const item of data?.grade_role_permission ?? []) {
    extraPermissionMap.set(item.permissions.name, item.permissions.cname);
  }
  return {
    permissions: data?.permissions,
    grade_roles: data?.grade_roles,
    grade_role_permission: data?.grade_role_permission,
    extraPermissions: data?.extra_permissions,
    extraPermissionMap,
  };
};

/**
 * 获取我的所属部门列表
 *
 * @returns 部门列表
 */
export const useMyDepartments = () => {
  const data = useUserProfile(true);

  return data?.departments;
};

/**
 * 获取用户下所有角色
 *
 * @returns 角色列表
 */
export const useMyRoles = () => {
  const query = useQuery({
    queryKey: UserKeys.ALLUSERROLE,
    queryFn: async (): Promise<UserRole[]> => {
      return await userService.getRoles();
    },
  });

  return query;
};

/**
 * 忘记密码第一步
 *
 * 获取验证码图片
 * @returns
 */
export const useCaptcha = () => {
  return useQuery({
    queryKey: UserKeys.CAPTCHA,
    queryFn: async () => {
      return userService.getCaptcha();
    },
    staleTime: 0,
    cacheTime: 0,
  });
};

/**
 * 忘记密码第二步
 *
 * 获取手机验证码的 Hook
 * @returns
 */
export const useSendOneTimePassword = () => {
  return useMutation({
    mutationFn: (data: SendOneTimePasswordDto) => {
      return userService.sendOneTimePassword(data);
    },
  });
};

/**
 * 忘记密码第三步
 *
 * 确认手机验证码的 Hook
 * @returns
 */
export const useVerifyOneTimePassword = () => {
  return useMutation({
    mutationFn: (data: VerifyOneTimePasswordDto) => {
      return userService.verifyOneTimePassword(data);
    },
  });
};

/**
 * 修改密码第四步
 *
 * 重新设置密码
 * @returns
 */
export const useResetPassword = () => {
  return useMutation({
    mutationFn: (data: ResetPasswordDto) => {
      return userService.resetPassword(data);
    },
  });
};
