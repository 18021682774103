import { useEffect, useState } from 'react';
import { getHiddenStoreKey, restoreHiddenKeys } from './utils';

export function useHiddenKeys(id: string) {
  const [hiddenKeys, setHiddenKeys] = useState(restoreHiddenKeys(id));

  useEffect(() => {
    window.localStorage.setItem(
      getHiddenStoreKey(id),
      JSON.stringify(hiddenKeys),
    );
  }, [id, hiddenKeys]);

  return [hiddenKeys, setHiddenKeys] as const;
}
