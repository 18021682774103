import clsx from 'clsx';
import {
  ComponentPropsWithRef,
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  PropsWithChildren,
  ReactNode,
  useRef,
} from 'react';
import S from './work-zone.module.less';

import { Paper } from '@/components';
import { WorkZoneContentContext } from '../work-zone.hook';

interface ContentProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  footer?: ReactNode;
  contentID?: string;
}

const WorkZoneContent: FC<PropsWithChildren<ContentProps>> = (props) => {
  const scroller = useRef<HTMLDivElement>(null);

  return (
    <div className={clsx(S.content, props.className)}>
      <div
        className={S['content-container']}
        id={props?.contentID || 'content'}
      >
        <WorkZoneContentContext.Provider value={scroller}>
          {props.children}
        </WorkZoneContentContext.Provider>
      </div>
      {props.footer && (
        <div className={S['footer-container']}>{props.footer}</div>
      )}
    </div>
  );
};

export interface WorkZoneFooterProps extends ComponentPropsWithRef<'div'> {
  actions?: ReactNode;
}

/**
 * 工作区的底部工具栏，需要放在 `WorkZone` 中
 *
 * ```tsx
 * const Page1 = () => {
 *   return (
 *     <WorkZone>
 *       <WorkZoneFooter actions={<Button type="primary">按钮</Button>}>
 *         <Button type="primary">其他按钮</Button>
 *       </WorkZoneFooter>
 *     </WorkZone>
 *   )
 * }
 * ```
 */
export const WorkZoneFooter: FC<PropsWithChildren<WorkZoneFooterProps>> = (
  props,
) => {
  const { actions, ...restProps } = props;

  return (
    <Paper {...restProps} className={clsx(S.footer, props.className)}>
      <div className="max-w-full overflow-auto">{props.children}</div>
      <div className="flex-auto"></div>
      {props.actions && <div className="ml-2">{props.actions}</div>}
    </Paper>
  );
};

export type WorkZoneProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

/**
 * 工作区边栏，需要放在 `WorkZone` 中
 *
 * ```tsx
 * const Page1 = () => {
 *   return (
 *     <WorkZone>
 *       <WorkZoneSider>
 *         边栏内容
 *       </WorkZoneSider>
 *     </WorkZone>
 *   )
 * }
 * ```
 */
export const WorkZoneSider: FC<
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = (props) => {
  const { children, ...restProps } = props;

  return (
    <WorkZoneContent
      {...restProps}
      className={clsx(S.sider, restProps.className)}
    >
      {children}
    </WorkZoneContent>
  );
};

/**
 * 工作区组件
 *
 * ```tsx
 * const Page1 = () => {
 *   return (
 *     <WorkZone>
 *       <WorkZoneFooter actions={<Button type="primary">按钮</Button>}>
 *         <Button type="primary">其他按钮</Button>
 *       </WorkZoneFooter>
 *       <WorkZoneSider>
 *         边栏内容
 *       </WorkZoneSider>
 *       主区域内容
 *     </WorkZone>
 *   )
 * }
 * ```
 */
export function WorkZone(props: PropsWithChildren<WorkZoneProps>) {
  const { children, ...restProps } = props;

  return (
    <div {...restProps} className={clsx(S.workzone, props.className)}>
      {children}
    </div>
  );
}

WorkZone.Sider = WorkZoneSider;
WorkZone.Content = WorkZoneContent;
WorkZone.Footer = WorkZoneFooter;
