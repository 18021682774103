import generatePicker from 'antd/es/date-picker/generatePicker';
import clsx from 'clsx';
import { endOfDay, startOfDay } from 'date-fns';
import { subDays } from 'date-fns/fp';
import { flow } from 'lodash-es';
import dateFnsGenerateConfig from 'rc-picker/es/generate/dateFns';
import { ComponentProps, forwardRef } from 'react';

import { useScrollContainerGetter } from '@/features/workspace';

import S from './date-picker.module.less';

const InnerDatePicker = generatePicker<Date>(dateFnsGenerateConfig);

export const DatePicker = forwardRef<
  InstanceType<typeof InnerDatePicker>,
  ComponentProps<typeof InnerDatePicker>
>((props, ref) => {
  const getContainer = useScrollContainerGetter();

  return (
    <InnerDatePicker ref={ref} getPopupContainer={getContainer} {...props} />
  );
});

export const DateRangePicker = forwardRef<
  InstanceType<typeof InnerDatePicker.RangePicker>,
  ComponentProps<typeof InnerDatePicker.RangePicker>
>((props, ref) => {
  const getContainer = useScrollContainerGetter();

  return (
    <InnerDatePicker.RangePicker
      ref={ref}
      popupClassName={clsx(S.override, props.popupClassName)}
      getPopupContainer={getContainer}
      ranges={{
        今天: [startOfDay(Date.now()), endOfDay(Date.now())],
        昨天: [
          flow(subDays(1))(startOfDay(Date.now())),
          flow(subDays(1))(endOfDay(Date.now())),
        ],
        前天: [
          flow(subDays(2))(startOfDay(Date.now())),
          flow(subDays(2))(endOfDay(Date.now())),
        ],
        近3天: [flow(subDays(2))(startOfDay(Date.now())), endOfDay(Date.now())],
        近7天: [flow(subDays(6))(startOfDay(Date.now())), endOfDay(Date.now())],
        近15天: [
          flow(subDays(14))(startOfDay(Date.now())),
          endOfDay(Date.now()),
        ],
        近30天: [
          flow(subDays(29))(startOfDay(Date.now())),
          endOfDay(Date.now()),
        ],
      }}
      {...props}
    ></InnerDatePicker.RangePicker>
  );
});
