import { Button, Empty, Layout } from 'antd';
import clsx from 'clsx';
import {
  ComponentProps,
  ComponentPropsWithoutRef,
  FC,
  ReactNode,
  forwardRef,
} from 'react';

import { Paper } from '@/components';

import { Scene } from './scene';
import { useScrollContainerRef } from './scroll-container.hook';

import S from './inline-details.module.less';

export interface InlineDetailsSiderProps
  extends ComponentPropsWithoutRef<'div'> {
  /**
   * 指示当前列表是否为空
   *
   * 当 `isEmpty` 为 null/undefined 时不会自动渲染
   *
   * 如果想要自动渲染空内容，则值必须为 `boolean`
   */
  isEmpty?: boolean;
  /**
   * 指示当前列表是否出错了
   *
   * 当 `isError` 为 null/undefined 时不会自动渲染
   *
   * 如果想要自动渲染错误内容，则值必须为 `boolean`
   */
  isError?: boolean;
  /**
   * 出错时的错误对象
   */
  error?: unknown;
  /**
   * 当内容为空时的描述
   */
  emptyDescription?: string;
  /**
   * 自定义内容为空时的内容
   */
  renderEmpty?: () => ReactNode;
  /**
   * 自定义出错时的内容
   *
   * @param error 错误对象
   */
  renderError?: (error: unknown) => ReactNode;
  /**
   * 重试的回调
   */
  onRetry?: () => void;
}

export const InlineDetailsSider = forwardRef<
  HTMLDivElement,
  InlineDetailsSiderProps
>((props, ref) => {
  const {
    isEmpty,
    isError,
    error,
    emptyDescription,
    renderEmpty,
    renderError,
    onRetry,
    children,
    ...restProps
  } = props;

  let content: ReactNode = children;

  if (isEmpty === true) {
    content = (
      <div className="my-6">
        {renderEmpty ? (
          renderEmpty()
        ) : (
          <Empty description={emptyDescription ?? '暂无数据'} />
        )}
      </div>
    );
  }

  if (isEmpty === true) {
    content = renderError ? (
      renderError(error)
    ) : (
      <div className="flex flex-col items-center">
        <Empty description="数据获取失败，请重试" />
        <div className="mt-4">
          <Button type="primary" ghost onClick={onRetry}>
            重试
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Layout.Sider ref={ref} width="33.3333%" className={clsx(S.sider)}>
      <Paper
        {...restProps}
        className={clsx('overflow-auto p-4 rounded', props.className)}
      >
        {content}
      </Paper>
    </Layout.Sider>
  );
});

export interface InlineDetailsContentProps
  extends ComponentPropsWithoutRef<typeof Layout.Content> {
  /**
   * 指示当前列表是否为空
   *
   * 当 `isEmpty` 为 null/undefined 时不会自动渲染
   *
   * 如果想要自动渲染空内容，则值必须为 `boolean`
   */
  isEmpty?: boolean;
  /**
   * 指示当前列表是否出错了
   *
   * 当 `isError` 为 null/undefined 时不会自动渲染
   *
   * 如果想要自动渲染错误内容，则值必须为 `boolean`
   */
  isError?: boolean;
  /**
   * 出错时的错误对象
   */
  error?: unknown;
  /**
   * 当内容为空时的描述
   */
  emptyDescription?: string;
  /**
   * 自定义内容为空时的内容
   */
  renderEmpty?: () => ReactNode;
  /**
   * 自定义出错时的内容
   *
   * @param error 错误对象
   */
  renderError?: (error: unknown) => ReactNode;
  /**
   * 重试的回调
   */
  onRetry?: () => void;
  /**
   * 操作区域的内容
   */
  actions?: ReactNode;
  /**
   * 底部区域的内容
   */
  footer?: ReactNode;
}

export const InlineDetailsContent: FC<InlineDetailsContentProps> = (props) => {
  const {
    children,
    actions,
    footer,
    isEmpty,
    emptyDescription,
    renderEmpty,
    isError,
    error,
    renderError,
    onRetry,
    ...restProps
  } = props;

  const scroller = useScrollContainerRef();

  let content: ReactNode = children;

  if (isEmpty) {
    content = renderEmpty ? (
      renderEmpty()
    ) : (
      <Empty description={emptyDescription ?? '请选择一个项'} />
    );
  }

  if (isError) {
    content = renderError ? (
      renderError(error)
    ) : (
      <div className="flex flex-col items-center">
        <Empty description="数据获取失败，请重试" />
        <div className="mt-4">
          <Button type="primary" ghost onClick={onRetry}>
            重试
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Layout.Content {...restProps} className={clsx('flex', 'flex-col')}>
      <Paper
        ref={scroller}
        className="relative p-4 rounded flex-[0 1 auto] overflow-auto"
      >
        {content}
      </Paper>
      {isEmpty !== true && isError !== true && (actions || footer) && (
        <Scene.Footer actions={actions}>{footer}</Scene.Footer>
      )}
    </Layout.Content>
  );
};

/**
 * 内联详情表单的容器组件
 *
 * 对表单的宽度做了限制
 */
export const InlineDetailsFormContainer: FC<ComponentProps<'div'>> = (
  props,
) => {
  return (
    <div
      {...props}
      className={clsx('w-[400px] max-w-full min-w-[50%]', props.className)}
    />
  );
};

export function InlineDetailsScene(props: ComponentProps<typeof Layout>) {
  return (
    <Layout
      {...props}
      className={clsx('h-full p-4 overflow-hidden', props.className)}
    />
  );
}

InlineDetailsScene.Sider = InlineDetailsSider;
InlineDetailsScene.Content = InlineDetailsContent;
InlineDetailsScene.FormContainer = InlineDetailsFormContainer;
