import { DetailedHTMLProps, ThHTMLAttributes } from 'react';
import { Resizable, ResizableProps } from 'react-resizable';

export interface ResizableHeaderProps
  extends Omit<
      DetailedHTMLProps<
        ThHTMLAttributes<HTMLTableCellElement>,
        HTMLTableCellElement
      >,
      'onResize'
    >,
    Pick<ResizableProps, 'onResize' | 'onResizeStart' | 'onResizeStop'> {
  width: number;
}

export const ResizableHeaderCell = (props: ResizableHeaderProps) => {
  const { width, onResize, onResizeStart, onResizeStop, ...restProps } = props;

  if (width == null) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      onResize={onResize}
      onResizeStart={onResizeStart}
      onResizeStop={onResizeStop}
      axis="x"
      handle={
        <div className="absolute top-0 right-[-5px] bottom-0 z-10 w-[10px] cursor-col-resize" />
      }
    >
      <th {...restProps} />
    </Resizable>
  );
};
