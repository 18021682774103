import { TableProps as AntdTableProps } from 'antd';
import type { ColumnsType, SortOrder } from 'antd/es/table/interface';
import clsx from 'clsx';
import {
  Key,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { sumBy } from 'lodash-es';
import { FowardedPagingTable } from '../paging-table';
import { useAuthorizedColumns } from './authorized-columns.hook';
import { buildColumn } from './column-builder';
import { useColumnWidth } from './column-width.hook';
import { ColumnsConfig, ColumnTypeExtend } from './column.types';
import { CustomExpandableConfig, useExpandable } from './expandable.hook';
import { ResizableHeaderCell } from './resizable-header-cell';
import S from './table.module.less';
import { Toolbar } from './toolbar';
import { ActionColumn, calcWidth, tryGetKey } from './utils';

export interface Sort {
  field: Key;
  order?: SortOrder;
}

export interface ComplexTableProps<T>
  extends Omit<AntdTableProps<T>, 'columns' | 'expandable'> {
  id: string;
  columns?: ColumnsConfig<T>;
  authorities?: string[];
  sort?: Sort;
  actionColumn?: ActionColumn<T>;
  primaryActions?: ReactNode;
  toolbar?: ReactNode;
  toolbarClassName?: string;
  defaultColumnWidth?: number;
  onSort?: (sort: Sort) => void;
  /**
   * 为表格行添加额外的内容，比如备注
   *
   * @param record 当前行数据
   * @returns
   */
  extra?: (record: T) => ReactNode;
  expandable?: CustomExpandableConfig<T>;
}

export function ComplexTable<T extends object>(props: ComplexTableProps<T>) {
  const [columns, setColumns] = useState<ColumnsConfig<T>>();
  const [resizing, setResizing] = useState(false);
  const [containerWidth, setContainerWidth] = useState<number>();

  const [widths, setWidths] = useColumnWidth(props.id);

  const authorizedColumns = useAuthorizedColumns(
    props.columns,
    props.authorities,
  );

  const antdColumns = useMemo(() => {
    return columns?.length
      ? buildColumn<T>(columns, props.sort, props.onSort)
      : void 0;
  }, [columns, props.sort, props.onSort]);

  const { actionColumn } = props;

  const hasRowSelection = useMemo(
    () => props.rowSelection,
    [props.rowSelection],
  );

  const resizableColumns = useMemo<ColumnsType<T>>(() => {
    if (antdColumns == null) {
      return [];
    }

    let availableWidth = containerWidth;

    return antdColumns.map<ColumnTypeExtend<T>>((col, i) => {
      const colKey = tryGetKey(col);
      let colWidth =
        widths[colKey] ?? calcWidth(col.width, props.defaultColumnWidth);

      if (i === antdColumns.length - 1 && availableWidth) {
        availableWidth -= +(actionColumn?.width ?? 200);
        availableWidth -= hasRowSelection ? 32 : 0;
        colWidth = availableWidth <= colWidth ? colWidth : availableWidth;
      } else if (availableWidth) {
        availableWidth -= colWidth;
      }

      return {
        ...col,
        width: colWidth,
        onHeaderCell: () => {
          return {
            width: colWidth,
            onResize: (...params: unknown[]) => {
              const [_, data] = params as any;
              setWidths((values) => ({ ...values, [colKey]: data.size.width }));
            },
            onResizeStart: () => {
              setResizing(true);
            },
            onResizeStop: () => {
              setResizing(false);
            },
          };
        },
      };
    });
  }, [
    antdColumns,
    widths,
    setWidths,
    props.defaultColumnWidth,
    containerWidth,
    actionColumn?.width,
    hasRowSelection,
  ]);

  const columnsWithAction = useMemo<ColumnsType<T>>(() => {
    if (!actionColumn) {
      return [...resizableColumns];
    }

    return [
      ...resizableColumns,
      {
        title: '操作',
        align: 'center',
        fixed: 'right',
        width: 200,
        ...actionColumn,
        key: '$$actions$$',
        render: (record: T) => actionColumn.render(record),
      },
    ];
  }, [resizableColumns, actionColumn]);

  const customExpandable = useExpandable(props);

  const onChangeColumns = useCallback((cols: ColumnsConfig<T>) => {
    setColumns(cols);
  }, []);

  const totalWidth = useMemo(() => {
    return sumBy(columnsWithAction, (item) => +(item.width ?? 0));
  }, [columnsWithAction]);

  const onResetColumns = () => {
    setWidths({});
  };

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    if (!ResizeObserver) {
      return;
    }

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setContainerWidth(entry.contentRect.width);
      }
    });
    resizeObserver.observe(containerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div ref={containerRef}>
      <Toolbar
        id={props.id}
        columns={authorizedColumns}
        toolbar={props.toolbar}
        toolbarClassName={props.toolbarClassName}
        primaryActions={props.primaryActions}
        onChangeColumns={onChangeColumns}
        onResetColumns={onResetColumns}
      />
      <FowardedPagingTable
        {...props}
        className={clsx(props.className, S.override, {
          resizing: resizing,
        })}
        tableLayout="fixed"
        scroll={{ ...props.scroll, x: totalWidth }}
        columns={columnsWithAction}
        components={{
          header: {
            cell: ResizableHeaderCell,
          },
        }}
        onChange={(pagination, filters, sorter, extra) => {
          if (extra.action === 'paginate') {
            props.onChange?.(pagination, filters, sorter, extra);
          }

          if (extra.action === 'sort') {
            if (Array.isArray(sorter)) {
              return;
            }

            const field = sorter.field;
            let key: Key | undefined;

            if (Array.isArray(field)) {
              [key] = field;
            } else {
              key = field as Key | undefined;
            }

            if (!key) {
              return;
            }

            props.onSort?.({ field: key, order: sorter.order });
          }
        }}
        expandable={customExpandable}
      />
    </div>
  );
}
