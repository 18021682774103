import { atom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useRef } from 'react';

export const scrollContainerAtom = atom<HTMLElement[]>([]);

/**
 * 获取祖先滚动容器
 *
 * 祖先滚动容器可能会有多个，但是只会获取离得最近的一个
 */
export const useScrollContainer = () => {
  const scrollers = useAtomValue(scrollContainerAtom);
  return scrollers.at(-1) ?? document.body;
};

/**
 * 获取祖先滚动容器的 getter
 */
export const useScrollContainerGetter = () => {
  const scroller = useScrollContainer();

  return () => scroller;
};

/**
 * 获取祖先滚动容器的标记
 *
 * 会返回一个
 */
export const useScrollContainerRef = () => {
  const setScrollContainer = useSetAtom(scrollContainerAtom);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!scrollContainerRef.current) {
      return;
    }

    setScrollContainer((oldContainers) => {
      if (!scrollContainerRef.current) {
        return oldContainers;
      }

      const newContainers = [...oldContainers];
      newContainers.push(scrollContainerRef.current);
      return newContainers;
    });

    return () => {
      setScrollContainer((oldContainers) => {
        const newContainers = [...oldContainers];
        newContainers.pop();
        return newContainers;
      });
    };
  }, [setScrollContainer]);

  return scrollContainerRef;
};
