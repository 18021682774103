import { useMemo } from 'react';
import { ColumnsConfig } from './column.types';

export function useAuthorizedColumns<T>(
  columns?: ColumnsConfig<T>,
  authorities?: string[],
): ColumnsConfig<T> | undefined {
  return useMemo(() => {
    if (!columns) {
      return;
    }

    return columns
      .map((column) => {
        if ('items' in column) {
          const { items } = column;
          column.items = items.filter((item) =>
            hasAuthority(item.authority, authorities),
          );
        }

        return column;
      })
      .filter((col) => {
        if (!hasAuthority(col.authority, authorities)) {
          return false;
        }

        if ('items' in col) {
          return col.items.length > 0;
        }

        return true;
      });
  }, [columns, authorities]);
}

export function hasAuthority(authority?: string, authorities?: string[]) {
  if (authorities == null) {
    return true;
  }

  if (authority == null) {
    return true;
  }

  return authorities.includes(authority);
}
