/**
 * 业务错误类
 *
 * 业务中会用到的基础错误类型，错误处理器将会对业务错误类进行特殊处理
 */
export class BizError extends Error {
  private _isHandled = false;

  get isHandled() {
    return this._isHandled;
  }

  /**
   * 将错误标记为已处理
   *
   * 大部分时候你都不需要手动去标记一个错误为已处理
   *
   * 只有在某些你能拿到错误对象，但又无法阻止他继续向上冒泡的时候才需要这么做
   */
  markAsHandled() {
    this._isHandled = true;
  }

  clone(): BizError {
    const options: ErrorOptions | undefined = this.cause
      ? { cause: this.cause }
      : void 0;
    return new BizError(this.message, options);
  }
}
