import { TableProps } from 'antd';
import type { ColumnType } from 'antd/es/table';
import { Key, ReactNode } from 'react';

import { ColumnWidthConfigs } from './column-width.hook';

export function isEmptyContent(content: ReactNode): boolean {
  if (content == null) {
    return true;
  }

  if (content === '') {
    return true;
  }

  if (content === false) {
    return true;
  }

  if (Array.isArray(content) && content.length === 0) {
    return true;
  }

  return false;
}

export function calcWidth(
  width?: number | 'datetime' | 'date',
  defaultWidth = 200,
): number {
  if (typeof width === 'number') {
    return width;
  }

  if (width === 'datetime') {
    return 180;
  }

  if (width === 'date') {
    return 100;
  }

  return defaultWidth;
}

export function tryGetKey<T>(item: Pick<ColumnType<T>, 'key' | 'dataIndex'>) {
  if (item.key) {
    return item.key;
  }

  if (item.dataIndex) {
    return item.dataIndex.toString();
  }

  throw new Error('表格的列必须提供 Key');
}

export interface ActionColumn<T>
  extends Pick<ColumnType<T>, 'width' | 'title' | 'fixed' | 'key' | 'align'> {
  render: (data: T) => ReactNode;
}

function getDataFromLocalstorage<T>(key: string): T | undefined;
function getDataFromLocalstorage<T>(key: string, defaultValue: T): T;
function getDataFromLocalstorage<T>(
  key: string,
  defaultValue?: T,
): T | undefined {
  const data = window.localStorage.getItem(key);

  if (!data) {
    return defaultValue;
  }

  try {
    return JSON.parse(data) as T;
  } catch {
    return defaultValue;
  }
}

export function getHiddenStoreKey(id: string) {
  return `erp/tables/${id}/hiddenKeys`;
}

export function getSortStoreKey(id: string) {
  return `erp/tables/${id}/sortKeys`;
}

export function getWidthStoreKey(id: string) {
  return `erp/tables/${id}/widths`;
}

export function restoreHiddenKeys(id: string) {
  return getDataFromLocalstorage<Key[]>(getHiddenStoreKey(id), []);
}

export function restoreSortKeys(id: string) {
  const sort = getDataFromLocalstorage<Key[]>(getSortStoreKey(id), []);

  return sort;
}

export function restoreWidthConfigs(id: string) {
  return getDataFromLocalstorage<ColumnWidthConfigs>(getWidthStoreKey(id), {});
}

/**
 * 根据 `rowKey` 的配置来获取对应行的 key
 */
export function getRowKey<T extends Record<string, any>>(
  rowKey: TableProps<T>['rowKey'],
  record: T,
) {
  let key: Key | undefined;

  if (typeof rowKey === 'string') {
    key = record[rowKey];
  } else {
    key = rowKey?.(record);
  }

  if (key == null) {
    throw new Error('未指定 Table 的 rowKey');
  }

  return key;
}
