import { Modal, ModalFuncProps } from '@/antd';

export interface ConfirmModalHookOptions extends ModalFuncProps {
  danger?: boolean;
}

export const useConfirmModal = () => {
  const [modal, contextHolder] = Modal.useModal();

  const confirm = (props: ConfirmModalHookOptions) => {
    const { danger, ...restProps } = props;

    modal.confirm({
      icon: null,
      ...restProps,
      okButtonProps: {
        danger: danger ?? props.okButtonProps?.danger,
        ...restProps.okButtonProps,
      },
    });
  };

  return [confirm, contextHolder] as const;
};
