import { RefSelectProps } from 'antd';
import { forwardRef, useMemo } from 'react';

import { Select, SelectProps } from '@/antd';

export interface LevelSelectProps extends SelectProps {
  /**
   * 级别数
   *
   * @default 10
   * TODO: 2023-07-12  将会变更为 50 级
   */
  levels?: number;
}

/**
 * 等级下拉框选择组件  level 1-10
 */
export const LevelSelect = forwardRef<RefSelectProps, LevelSelectProps>(
  (props, ref) => {
    const { levels = 10, ...selectProps } = props;

    const options = useMemo<SelectProps['options']>(
      () =>
        Array.from({ length: levels }).map((_, i) => ({
          label: i + 1,
          value: i + 1,
        })),
      [levels],
    );

    return <Select ref={ref} options={options} {...selectProps} />;
  },
);
