import AliyunUploadAdapter from './aliyun-upload-adapter';
import type { BaseUploadAdapter } from '@/utils/upload/types';

class Upload {
  adapter: BaseUploadAdapter;

  constructor() {
    this.adapter = new AliyunUploadAdapter();
  }

  async put(file: any, options?: any) {
    return await this.adapter.put(file, options);
  }

  async get(name: string, options?: any) {
    return await this.adapter.get(name, options);
  }
}

export default Upload;
