import { RefObject, createContext, useContext } from 'react';

export const WorkZoneContentContext =
  createContext<RefObject<HTMLDivElement> | null>(null);

export const useWorkZoneContainer = () => {
  const container = useContext(WorkZoneContentContext);

  return container?.current;
};
