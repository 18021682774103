import { message, UploadProps } from 'antd';
import type { RcFile } from 'antd/es/upload';
import Upload from './upload';
const upload = new Upload();

const TwoMByte = 2097152;

export function createCustomUploadRequest<
  T = unknown,
>(): UploadProps<T>['customRequest'] {
  return (params) => {
    const { file, onError, onSuccess, data } = params;
    const putOptions = data?.moduleType
      ? { saveParams: { module_type: data.moduleType } }
      : null;
    upload
      .put(file, putOptions)
      .then((res) => {
        if (onSuccess) {
          onSuccess(res);
        }
      })
      .catch((err) => {
        message.error(err.message);
        if (onError) {
          onError(err);
        }
      });
  };
}

export const customUploadRequest = createCustomUploadRequest();

export function beforeUploadImage(file: RcFile) {
  const type = file.type;
  const mainType = type.split('/');

  if (!mainType.length || mainType[0] !== 'image') {
    message.warning('只能上传图片');
    return false;
  } else if (file.size > TwoMByte) {
    message.warning('图片限制最大2MB');
    return false;
  } else if (
    type !== 'image/jpg' &&
    type !== 'image/png' &&
    type !== 'image/webp' &&
    type !== 'image/jpeg'
  ) {
    message.warning('只能上传jpg，jpeg，png和webp格式的图片');
    return false;
  }

  return true;
}

export default upload;
