import { Modal as AntdModal, ModalProps } from 'antd';
import clsx from 'clsx';
import { FC, ReactElement } from 'react';

import S from './modal.module.less';

export type { ModalFuncProps, ModalProps } from 'antd';

export const Modal = ((props: ModalProps): ReactElement => {
  return <AntdModal {...props} className={clsx(S.override, props.className)} />;
}) as FC<ModalProps> & {
  config: typeof AntdModal.config;
  confirm: typeof AntdModal.confirm;
  destroyAll: typeof AntdModal.destroyAll;
  error: typeof AntdModal.error;
  info: typeof AntdModal.info;
  success: typeof AntdModal.success;
  useModal: typeof AntdModal.useModal;
  warn: typeof AntdModal.warn;
  warning: typeof AntdModal.warning;
};

Modal.config = AntdModal.config;
Modal.confirm = AntdModal.confirm;
Modal.destroyAll = AntdModal.destroyAll;
Modal.error = AntdModal.error;
Modal.info = AntdModal.info;
Modal.success = AntdModal.success;
Modal.useModal = AntdModal.useModal;
Modal.warn = AntdModal.warn;
Modal.warning = AntdModal.warning;

/**
 * 不带 Footer 的 Modal
 */
export const PureModal = (props: ModalProps) => (
  <Modal {...props} footer={null} />
);
