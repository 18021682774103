import { Layout } from 'antd';
import clsx from 'clsx';
import {
  ComponentProps,
  ComponentPropsWithoutRef,
  forwardRef,
  ReactNode,
} from 'react';

import { Paper } from '@/components';

import { DetailsScene } from './details';
import { InlineDetailsScene } from './inline-details';
import { TableListScene } from './table-list';

import S from './scene.module.less';

export interface SceneFooterProps extends ComponentPropsWithoutRef<'div'> {
  actions?: ReactNode;
}

export const SceneFooter = forwardRef<HTMLDivElement, SceneFooterProps>(
  (props, ref) => {
    const { actions, ...restProps } = props;

    return (
      <Paper
        ref={ref}
        {...restProps}
        className={clsx(S.footer, props.className)}
      >
        <div className="max-w-full overflow-auto">{props.children}</div>
        <div className="flex-auto"></div>
        {props.actions && <div className="ml-2">{props.actions}</div>}
      </Paper>
    );
  },
);

export function Scene(props: ComponentProps<typeof Layout.Content>) {
  return <Layout.Content {...props} />;
}

Scene.TableList = TableListScene;
Scene.Details = DetailsScene;
Scene.InlineDetails = InlineDetailsScene;
Scene.Footer = SceneFooter;
