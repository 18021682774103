// import React from 'react';

import type { QueryClientConfig } from '@tanstack/react-query';
import { setupAxios, setupYup } from './core';
import { version } from './version';

// 无法挂载 store，所以废弃
// import AccessProvider from './components/access/provider';

// export const rootContainer = (container: React.ReactElement) => {
//   return React.createElement(AccessProvider, null, container);
// };

// init axios
setupAxios();
setupYup();

const queryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      // 关闭在窗口获取焦点的时候刷新数据
      refetchOnWindowFocus: false,
      // 关闭自动重试
      retry: false,
    },
  },
};

export const reactQuery = {
  queryClient: queryClientConfig,
};

const versionInfo = `版本号：${version}`;
// eslint-disable-next-line no-console
console.log(`%c${versionInfo}`, 'color:#3461ff');
