import { Form, FormProps } from 'antd';
import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';

import S from './multi-input-form.module.less';

export const MultiInputForm: FC<PropsWithChildren<FormProps>> = (props) => {
  return (
    <Form
      className={clsx(S.override, props.className)}
      {...props}
      layout="inline"
    >
      {props.children}
    </Form>
  );
};
