import { noop } from 'lodash-es';
import { FC, useEffect, useState } from 'react';

import { Modal as AntdModal, ModalProps as AntdModalProps } from '@/antd';
import { useOverlayRef } from '@/common/hooks';

export interface ModalProps extends Omit<AntdModalProps, 'visible'> {
  /**
   * 默认这个 Modal 只能用在 Overlay 中
   *
   * 可以通过设置 `outOfOverlay` 为 `true` 让其在在 Overlay 外也能使用
   */
  outOfOverlay?: boolean;
}

export const Modal: FC<ModalProps> = (props) => {
  const [open, setOpen] = useState(true);
  const [resolve, setResolve] = useState(() => noop);

  const overlayRef = useOverlayRef(props.outOfOverlay ?? false);

  useEffect(() => {
    const dispose = overlayRef?.onBeforeClose(() => {
      // 等 Modal 的关闭动画完成后再销毁组件
      return new Promise<void>((resolve) => {
        setOpen(false);
        setResolve(() => resolve);
      });
    });

    return () => {
      dispose?.();
    };
  }, [overlayRef]);

  useEffect(() => {
    if (overlayRef) {
      return;
    }

    setOpen(props.open ?? false);
  }, [props.open, overlayRef]);

  return (
    <AntdModal
      {...props}
      open={open}
      afterClose={() => {
        props.afterClose?.();
        resolve?.();
      }}
      onOk={(e) => {
        props.onOk?.(e);
      }}
      onCancel={(e) => {
        props.onCancel?.(e);
      }}
    >
      {props.children}
    </AntdModal>
  );
};
